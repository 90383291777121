import { CategoryType } from '@scandipwa/scandipwa/src/type/ProductList.type';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import next from './assets/next.svg';
import prev from './assets/prev.svg';
import CategoryNavItemComponent from './CategoryNavItem.component';

import './CategoryNav.style';

/** @namespace Pwa/Component/CategoryNav/Component */
export class CategoryNavComponent extends PureComponent {
    static propTypes = {
        category: CategoryType.isRequired,
        isCurrentCategoryLoaded: PropTypes.bool.isRequired,
        wrapperRef: PropTypes.object.isRequired,
        scrollLeft: PropTypes.func.isRequired,
        scrollRight: PropTypes.func.isRequired,
        offSet: PropTypes.number.isRequired,
    };

    componentDidMount() {
        this.checkWidth();
    }

    componentDidUpdate() {
        this.checkWidth();
    }

    checkWidth() {
        const { wrapperRef } = this.props;

        const maxScrollLeft = wrapperRef?.current?.scrollWidth - wrapperRef?.current?.clientWidth;

        if (maxScrollLeft === 0) {
            wrapperRef.current.parentNode.classList.add('NavWrapper_Center');
        }
    }

    renderPlaceholder() {
        return (
            <div block="CategoryNav">
                <div block="FullWidthWrapper">
                    <div block="CategoryNav" elem="Loader" />
                </div>
            </div>
        );
    }

    render() {
        const {
            category, isCurrentCategoryLoaded, wrapperRef, scrollLeft, scrollRight, offSet,
        } = this.props;

        if (!isCurrentCategoryLoaded || category === undefined) {
            return this.renderPlaceholder();
        }

        if (wrapperRef.current) {
            wrapperRef.current.scrollLeft = offSet;
        }

        const { mc_nav } = category;

        if (mc_nav === undefined) {
            return null;
        }

        return (
            <div block="CategoryNav">
                <h1>{ category.name }</h1>
                { mc_nav.length > 0 && (
                    <div block="FullWidthWrapper">
                        <div block="NavWrapper">
                            <button block="NavWrapper" elem="Prev" onClick={ scrollLeft }>
                                <img src={ prev } alt="prev" />
                            </button>
                            <div block="CategoryNav" elem="List" ref={ wrapperRef }>
                                { mc_nav.map((item) => {
                                    const {
                                        url,
                                        photo,
                                        name,
                                        product_url,
                                    } = item;

                                    return (
                                        <CategoryNavItemComponent
                                          url={ url }
                                          photo={ photo }
                                          name={ name }
                                          key={ url }
                                          product_url={ product_url }
                                          category_url={ category.url }
                                        />
                                    );
                                }) }
                            </div>
                            <button block="NavWrapper" elem="Next" onClick={ scrollRight }>
                                <img src={ next } alt="prev" />
                            </button>
                        </div>
                    </div>
                ) }
            </div>
        );
    }
}

export default CategoryNavComponent;
