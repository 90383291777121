import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CategoryConfigurableAttributes from 'Component/CategoryConfigurableAttributes';
import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import Loader from 'Component/Loader';
import Overlay from 'Component/Overlay';
import ResetButton from 'Component/ResetButton';

import { CATEGORY_FILTER_OVERLAY_ID } from './CategoryFilterOverlay.config';

import './CategoryFilterOverlay.style';

/** @namespace Pwa/Component/CategoryFilterOverlay/Component */
export class CategoryFilterOverlayComponent extends PureComponent {
    static propTypes = {
        availableFilters: PropTypes.objectOf(PropTypes.shape).isRequired,
        areFiltersEmpty: PropTypes.bool.isRequired,
        isMatchingInfoFilter: PropTypes.bool,
        isInfoLoading: PropTypes.bool.isRequired,
        isProductsLoading: PropTypes.bool.isRequired,
        onSeeResultsClick: PropTypes.func.isRequired,
        onResetClick: PropTypes.func.isRequired,
        onVisible: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired,
        customFiltersValues: PropTypes.objectOf(PropTypes.array).isRequired,
        availableFiltersByAttributeSet: PropTypes.objectOf(PropTypes.array)
            .isRequired,
        toggleCustomFilter: PropTypes.func.isRequired,
        getFilterUrl: PropTypes.func.isRequired,
        totalPages: PropTypes.number.isRequired,
        closeCategoryFiltersMenu: PropTypes.func.isRequired,
        applyFiltersButton: PropTypes.func.isRequired,
        isShowCategoryFilters: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        isMatchingInfoFilter: false,
    };

    renderPromotionalCheckBox() {
        return (
      <div className="PromotionalCheckbox-Wrapper">
        <Field
          attr={ {
              id: 'PromotionalCheckbox',
              name: 'PromotionalCheckbox',
          } }
          mix={ {
              block: 'CategoryFilters',
              elem: 'Checkbox',
          } }
          type={ FieldType.CHECKBOX }
          label="in promozione"
        />
      </div>
        );
    }

    renderFilters() {
        const {
            availableFiltersByAttributeSet,
            customFiltersValues,
            toggleCustomFilter,
            isMatchingInfoFilter,
            getFilterUrl,
        } = this.props;

        return (
      <CategoryConfigurableAttributes
        mix={ { block: 'CategoryFilterOverlay', elem: 'Attributes' } }
        isReady={ isMatchingInfoFilter }
        configurable_options={ availableFiltersByAttributeSet() }
        getLink={ getFilterUrl }
        parameters={ customFiltersValues }
        updateConfigurableVariant={ toggleCustomFilter }
      />
        );
    }

    renderSeeResults() {
        const { onSeeResultsClick } = this.props;

        return (
      <div block="CategoryFilterOverlay" elem="SeeResults">
        <button
          block="CategoryFilterOverlay"
          elem="Button"
          mix={ { block: 'Button' } }
          onClick={ onSeeResultsClick }
        >
          { __('SEE RESULTS') }
        </button>
      </div>
        );
    }

    renderApplyButton() {
        const { applyFiltersButton } = this.props;

        return (
      <div block="CategoryFilterOverlay" elem="SeeResults">
        <button
          block="CategoryFilterOverlay"
          elem="Button"
          alt="cta_global"
          mix={ { block: 'Button' } }
          onClick={ applyFiltersButton }
        >
          { __('APPLY') }
        </button>
      </div>
        );
    }

    renderResetButton() {
        const { onResetClick } = this.props;

        return (
      <ResetButton
        onClick={ onResetClick }
        mix={ {
            block: 'CategoryFilterOverlay',
            elem: 'ResetButton',
            className: 'Button selected',
        } }
      />
        );
    }

    renderNoResults() {
        return (
      <p block="CategoryFilterOverlay" elem="NoResults">
        { __(`The selected filter combination returned no results.
                Please try again, using a different set of filters.`) }
      </p>
        );
    }

    renderEmptyFilters() {
        return (
            <>
        { this.renderNoResults() }
        <div block="CategoryFilterOverlay" elem="FiltriButtonWrapper">
          { this.renderResetButton() }
          { this.renderSeeResults() }
        </div>
            </>
        );
    }

    renderMinimalFilters() {
        return (
      <div block="CategoryFilterOverlay" elem="FiltriButtonWrapper">
        { this.renderResetButton() }
        { this.renderSeeResults() }
      </div>
        );
    }

    renderDefaultFilters() {
        return (
            <>
        { this.renderFilters() }
        <div block="CategoryFilterOverlay" elem="FiltriButtonWrapper">
          { this.renderResetButton() }
          { this.renderApplyButton() }
        </div>
            </>
        );
    }

    renderContent() {
        const { totalPages, areFiltersEmpty, isProductsLoading } = this.props;

        if (!isProductsLoading && totalPages === 0) {
            return this.renderEmptyFilters();
        }

        if (areFiltersEmpty) {
            return this.renderMinimalFilters();
        }

        return this.renderDefaultFilters();
    }

    renderLoader() {
        const { isInfoLoading, availableFilters } = this.props;

        const isLoaded = availableFilters && !!Object.keys(availableFilters).length;

        if (!isLoaded) {
            // hide loader if filters were not yet loaded (even once!)
            return null;
        }

        return <Loader isLoading={ isInfoLoading } />;
    }

    renderCloseButton() {
        const { closeCategoryFiltersMenu } = this.props;

        return (
      <button
        className="CloseButton"
        type="submit"
        onClick={ closeCategoryFiltersMenu }
        mix={ {
            block: 'CategoryFilters',
            elem: 'Button',
        } }
      >
        X
      </button>
        );
    }

    render() {
        const {
            onVisible,
            onHide,
            isShowCategoryFilters,
            closeCategoryFiltersMenu,
        } = this.props;

        if (!isShowCategoryFilters) {
            return null;
        }

        return (
      <Overlay
        onVisible={ onVisible }
        onHide={ onHide }
        mix={ { block: 'CategoryFilterOverlay' } }
        id={ CATEGORY_FILTER_OVERLAY_ID }
        isRenderInPortal={ false }
      >
        <div
          className="CategoryFilters-Modal-Outside"
          onClick={ closeCategoryFiltersMenu }
        />
        <div className="CategoryFilters-Modal">
          { this.renderCloseButton() }
          <div className="ModalTitleWrapper">
            <h5 className="ModalTitle">Filtra per</h5>
          </div>
          { this.renderContent() }
          { this.renderLoader() }
        </div>
      </Overlay>
        );
    }
}

export default CategoryFilterOverlayComponent;
