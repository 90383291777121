import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { resetSubCategory, selectSubCategory } from 'Store/SubCategoryFilterTags/SubCategoryFilterTags.action';
import { CategoryType } from 'Type/ProductList.type';

import CategoryPageFilterTag from './CategoryPageFilterTag.component';

export const CustomCategoryDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/CustomCategory/CustomCategory.dispatcher'
);

/** @namespace Pwa/Component/CategoryPageFilterTag/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    categoryRetrievedById: state.CustomCategoryReducer.categoryRetrievedById,
    subCategorySelected: state.SubCategoryFilterTagsReducer.subCategorySelected,
});

/** @namespace Pwa/Component/CategoryPageFilterTag/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    customRequestCategory: (options) => CustomCategoryDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
    ),
    filterTagSelectSubCategory: (categoryName) => dispatch(selectSubCategory(categoryName)),
    resetTagClearSubCategory: (categoryName) => dispatch(resetSubCategory(categoryName)),
});

/** @namespace Pwa/Component/CategoryPageFilterTag/Container */
export class CategoryPageFilterTagContainer extends PureComponent {
    static propTypes = {
        categoryRetrievedById: CategoryType,
        currentCategory: CategoryType.isRequired,
        customRequestCategory: PropTypes.func.isRequired,
        subCategorySelected: PropTypes.string,
        resetTagClearSubCategory: PropTypes.func.isRequired,
    };

    static defaultProps = {
        categoryRetrievedById:
            {
                name: '',
                url_path: '',
                breadcrumbs: [{
                    name: '',
                    url_path: '',
                }],
            },
        subCategorySelected: '',
    };

    __construct() {
        this.state = {
            isCustomCategoryRequested: false,
        };
        this.customRequestCategory = this.customRequestCategory.bind(this);
        this.localSelectSubCategory = this.localSelectSubCategory.bind(this);
        this.localResetSubCategory = this.localResetSubCategory.bind(this);
    }

    componentDidMount() {}

    customRequestCategory(categoryIds) {
        const { customRequestCategory } = this.props;
        customRequestCategory({
            categoryIds,
        });
        this.setState({ isCustomCategoryRequested: true });
    }

    localSelectSubCategory(categoryUrl) {
        // const {
        //     filterTagSelectSubCategory
        // } = this.props;
        //
        // filterTagSelectSubCategory(categoryUrl);
        /* saving redux status in local storage, so that it survives after refreshing */
        localStorage.setItem('subCategorySelected', categoryUrl);
        window.location.reload();
    }

    localResetSubCategory() {
        const {
            resetTagClearSubCategory,
        } = this.props;

        resetTagClearSubCategory();
    }

    render() {
        const {
            isCustomCategoryRequested,
        } = this.state;

        const {
            currentCategory, categoryRetrievedById,
            subCategorySelected,
        } = this.props;

        return (
            <CategoryPageFilterTag
              currentCategory={ currentCategory }
              isCustomCategoryRequested={ isCustomCategoryRequested }
              categoryRetrievedById={ categoryRetrievedById }
              customRequestCategory={ this.customRequestCategory }
              subCategorySelected={ subCategorySelected }
              selectSubCategory={ this.localSelectSubCategory }
              clearSubCategory={ this.localResetSubCategory }
              resetSubCategorySelected={ this.localResetSubCategory }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPageFilterTagContainer);
