import { createRef } from 'react';

import AddIcon from 'Component/AddIcon';
import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import MinusIcon from 'Component/MinusIcon';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import {
    ExpandableContentComponent as SourceExpandableContent,
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';
// import { isCrawler, isSSR } from 'Util/Browser';
import { getFixedElementHeight } from 'Util/CSS';
/** @namespace Pwa/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends SourceExpandableContent {
    static defaultProps = {
        heading: '',
        isContentExpanded: true,
        onClick: undefined,
        children: [],
        isArrow: false,
        mods: {},
    };

    expandableContentRef = createRef();

    __construct(props) {
        super.__construct(props);
        const { isContentExpanded } = this.props;

        // const isForceExpanded = isSSR() || isCrawler();
        this.toggleExpand = this.toggleExpand.bind(this);

        this.state = {
            isContentExpanded,
            // eslint-disable-next-line react/no-unused-state
            prevIsContentExpanded: isContentExpanded,
        };
    }

    static getDerivedStateFromProps(
        { isContentExpanded },
        { prevIsContentExpanded },
    ) {
        if (isContentExpanded !== prevIsContentExpanded) {
            return {
                prevIsContentExpanded: isContentExpanded,
                isContentExpanded,
            };
        }

        return null;
    }

    toggleExpand() {
        const { onClick } = this.props;

        if (onClick) {
            onClick();

            return;
        }

        this.setState(
            ({ isContentExpanded }) => ({ isContentExpanded: !isContentExpanded }),
            () => this.scrollToExpandedContent(),
        );
    }

    scrollToExpandedContent() {
        const { isContentExpanded } = this.state;
        const elem = this.expandableContentRef && this.expandableContentRef.current;

        if (isContentExpanded && !elem) {
            return;
        }

        const elemToWindowTopDist = elem.getBoundingClientRect().top;
        const windowToPageTopDist = document.body.getBoundingClientRect().top;
        const topToElemDistance = elemToWindowTopDist - windowToPageTopDist;
        const {
            total: totalFixedElementHeight,
            bottom: bottomFixedElementHeight,
        } = getFixedElementHeight();

        const elemMaxOffsetHeight = screen.height > elem.offsetHeight + bottomFixedElementHeight
            ? elem.offsetHeight
            : screen.height - totalFixedElementHeight;
        const scrollTo = topToElemDistance - (screen.height - bottomFixedElementHeight - elemMaxOffsetHeight);

        // checking if button is in a view-port
        if (-windowToPageTopDist >= scrollTo) {
            // return;
        }
        // elem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }

    renderButton() {
        const { isContentExpanded } = this.state;
        const { heading, mix } = this.props;

        return (
            <button
              block="ExpandableContent"
              elem="Button"
              mods={ { isContentExpanded } }
              mix={ { ...mix, elem: 'ExpandableContentButton' } }
              onClick={ this.toggleExpand }
              onKeyDown={ this.toggleExpand }
            >
                <span
                  block="ExpandableContent"
                  elem="Heading"
                  mix={ { ...mix, elem: 'ExpandableContentHeading' } }
                >
                    { typeof heading === 'string' ? (
                        <TextPlaceholder content={ heading } length={ TextPlaceHolderLength.MEDIUM } />
                    ) : (
                        heading
                    ) }
                </span>
                { this.renderButtonIcon() }
            </button>
        );
    }

    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const { isArrow, device: { isMobile } } = this.props;

        if (!isMobile) {
            return null;
        }

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? Directions.TOP : Directions.BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }

    renderTogglePlusMinus() {
        const { isContentExpanded } = this.state;

        if (isContentExpanded) {
            return <MinusIcon />;
        }

        return <AddIcon />;
    }

    renderContent() {
        const { children, mix } = this.props;
        const { isContentExpanded } = this.state;
        const mods = { isContentExpanded };

        return (
            <div
              block="ExpandableContent"
              elem="Content"
              mods={ mods }
              mix={ { ...mix, elem: 'ExpandableContentContent', mods } }
            >
                { children }
            </div>
        );
    }

    render() {
        const { mix, mods } = this.props;

        return (
            <article
              block="ExpandableContent"
              mix={ mix }
              mods={ mods }
              ref={ this.expandableContentRef }
            >
                { this.renderButton() }
                { this.renderContent() }
            </article>
        );
    }
}

export default ExpandableContentComponent;
