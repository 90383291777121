/* eslint-disable no-return-assign */
import PropTypes from 'prop-types';
import {
    Suspense,
} from 'react';

import CategoryDescription from 'Component/CategoryDescription';
import CategoryFilterOverlay from 'Component/CategoryFilterOverlay';
import CategoryNav from 'Component/CategoryNav';
import CategoryPageFilterTag from 'Component/CategoryPageFilterTag/CategoryPageFilterTag.container';
import CategoryProductList from 'Component/CategoryProductList';
import CategoryTitle from 'Component/CategoryTitle';
import ClickOutside from 'Component/ClickOutside';
import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
import IfStore from 'Component/IfStore';
import Overlay from 'Component/Overlay';
import { CategoryPageComponent as SourceCategoryPage } from 'SourceRoute/CategoryPage/CategoryPage.component';
import { isCrawler, isSSR } from 'Util/Browser';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { startMobile } from 'Util/mcMobileCheck';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

import {
    CATEGORY_FILTERS_BUTTON,
    LAYOUT_KEY,
    ONE_COLUMN_LAYOUT,
    ORDER_BY_OVERLAY,
    ORDER_BY_TOGGLE,
    PRICE_FILTER,
    THREE_COLUMN_LAYOUT,
    TWO_COLUMN_LAYOUT,
} from './CategoryPage.config';

import './CategoryPage.style';

/** @namespace Pwa/Route/CategoryPage/Component */
export class CategoryPageComponent extends SourceCategoryPage {
    static propTypes = {
        ...SourceCategoryPage.propTypes,
        onLayoutSwitcherClick: PropTypes.func.isRequired,
        onOrderByOpen: PropTypes.func.isRequired,
        onOrderByOutsideClick: PropTypes.func.isRequired,
        openCategoryFilter: PropTypes.func.isRequired,
        filtersEnabled: PropTypes.func.isRequired,
        pageSize: PropTypes.number,
        totalItemsSubCategoryFiltered: PropTypes.number.isRequired,
    };

    static defaultProps = {
        ...SourceCategoryPage.defaultProps,
        pageSize: 24,
    };

    state = {
        hide: false,
    };

    layoutSwitcher = [
        ONE_COLUMN_LAYOUT,
        TWO_COLUMN_LAYOUT,
        THREE_COLUMN_LAYOUT,
    ];

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentDidUpdate() {
        document.body.style.marginTop = '0';
    }

    static getDerivedStateFromProps(props) {
        const {
            selectedLayoutType,
        } = props;

        const storedPlpType = BrowserDatabase.getItem(LAYOUT_KEY) || selectedLayoutType;

        const activeLayoutType = startMobile() ? ONE_COLUMN_LAYOUT : storedPlpType || TWO_COLUMN_LAYOUT;

        return { activeLayoutType };
    }

    renderCategoryTitle() {
        const {
            category,
            isCurrentCategoryLoaded,
        } = this.props;

        return (
            <CategoryTitle
              category={ category }
              isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
            />
        );
    }

    renderCategoryDescription() {
        const {
            category,
            isCurrentCategoryLoaded,
        } = this.props;

        return (
            <CategoryDescription
              category={ category }
              isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
            />
        );
    }

    renderCategoryNav() {
        const {
            category,
            isCurrentCategoryLoaded,
        } = this.props;

        return (
            <CategoryNav
              category={ category }
              isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
            />
        );
    }

    renderCategoryPageFilterTag() {
        const {
            category,
        } = this.props;

        return (
            <IfStore storeCode="default">
                <CategoryPageFilterTag
                  currentCategory={ category }
                />
            </IfStore>
        );
    }

    renderCertificationHeader() {
        const { category: { url_path } } = this.props;

        const patternIt = /cucin/i;
        const exclusionElettrodomesticiIt = /elettrodomestici/i;
        const exclusionLavelliIt = /lavelli/i;
        // const patternEs = /cocin/i;

        if (url_path !== undefined && url_path !== null
            && (patternIt.test(url_path) === true
                && exclusionElettrodomesticiIt.test(url_path) === false
                && exclusionLavelliIt.test(url_path) === false
                // || patternEs.test(url_path) === true
            )) {
            return (
                <div className="cucine-head-certification">
                    <div className="cucine-head-certification-logo" />
                    <div className="cucine-head-certification-description">
                        <div className="cucine-head-certification-description-title">
                            { __('MADE IN ITALY, QUELLO CERTIFICATO') }
                        </div>
                        <div className="cucine-head-certification-description-paragraph">
                            { __('I marchi') }
                            &nbsp;
                            <strong>{ __('IT Catas') }</strong>
                            &nbsp;
                            { __('e') }
                            &nbsp;
                            <strong>{ __('IT Cosmob') }</strong>
                            &nbsp;
                            { __('offrono una certificazione che attesta l’origine italiana del mobile e l’idoneità a criteri di') }
                            &nbsp;
                            <strong>{ __('sicurezza, resistenza e durabilità') }</strong>
                            .
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    renderCategoryTop() {
        const { category: { cms_block } } = this.props;

        if (cms_block) {
            const { identifier } = cms_block;

            return (
                <CmsBlock identifier={ identifier } mix={ { block: 'CategoryPage', elem: 'Banner' } } />
            );
        }

        return this.renderCategoryNav();
    }

    renderLayoutSwitcher(layout) {
        const { onLayoutSwitcherClick, selectedLayoutType } = this.props;

        const storedPlpType = BrowserDatabase.getItem(LAYOUT_KEY) || selectedLayoutType;

        return (
            <div
              key={ `layout-${layout}` }
              block="CategoryPage"
              elem="LayoutSwitcher"
              role="button"
              tabIndex="0"
              onClick={ () => onLayoutSwitcherClick(layout) }
              onKeyDown={ () => onLayoutSwitcherClick(layout) }
              mods={ { isActive: (layout === storedPlpType) } }
            >
                <span
                  block="CategoryPage"
                  elem="LayoutIcon"
                  mods={ { layout } }
                />
            </div>

        );
    }

    renderOrderByToggle() {
        const { onOrderByOpen } = this.props;

        return (
            <div
              block="CategoryPage"
              elem="OrderByToggle"
              key={ ORDER_BY_TOGGLE }
              role="button"
              tabIndex="0"
              onClick={ onOrderByOpen }
              onKeyDown={ onOrderByOpen }
            >
                <span>
                    { __('Ordina') }
                </span>
                <i className="fa fa-chevron-right" />
            </div>
        );
    }

    renderOrderByOverlay() {
        const { selectedSort: { sortDirection, sortKey }, onSortChange } = this.props;
        const isPriceFilter = (sortKey === PRICE_FILTER);

        return (
            <Overlay
              id={ ORDER_BY_OVERLAY }
              bypassMobile
              mix={ { block: 'CategoryPage', elem: 'OrderByOverlay' } }
            >
                <span
                  block="CategoryPage"
                  elem="OrderOption"
                  mods={ { isActive: (isPriceFilter && (sortDirection === 'ASC')) } }
                  onClick={ () => onSortChange('ASC', PRICE_FILTER) }
                  onKeyDown={ () => onSortChange('ASC', PRICE_FILTER) }
                >
                    { __('Prezzo più basso') }
                </span>
                <span
                  block="CategoryPage"
                  elem="OrderOption"
                  mods={ { isActive: (isPriceFilter && (sortDirection === 'DESC')) } }
                  onClick={ () => onSortChange('DESC', PRICE_FILTER) }
                  onKeyDown={ () => onSortChange('DESC', PRICE_FILTER) }
                >
                    { __('Prezzo più alto') }
                </span>
            </Overlay>

        );
    }

    renderCategoryFilterButton() {
        const { openCategoryFilter } = this.props;

        return (
            <div
              block="CategoryPage"
              elem="CategoryFiltersButton"
              key={ CATEGORY_FILTERS_BUTTON }
              role="button"
              tabIndex="0"
              onClick={ openCategoryFilter }
              onKeyDown={ openCategoryFilter }
            >
                <span
                  block="CategoryPage"
                  elem="FiltriText"
                  alt="cta_global"
                >
                    { __('Filtri') }
                </span>
                <span
                  block="CategoryPage"
                  elem="FiltriIcon"
                  alt="cta_global"
                />
            </div>
        );
    }

    renderLayoutSwitchers() {
        return !startMobile() && (
            <div block="CategoryPage" elem="ActionBar">
                { this.layoutSwitcher.map((layout) => this.renderLayoutSwitcher(layout)) }
            </div>
        );
    }

    renderFiltersButton() {
        const {
            filtersEnabled,
        } = this.props;

        if (!filtersEnabled()) {
            return null;
        }

        return (
            <div className="filter-button-container">
                <div block="CategoryPage" elem="ActionBar">
                    { this.renderCategoryFilterButton() }
                </div>
            </div>
        );
    }

    renderActionBar() {
        const { onOrderByOutsideClick } = this.props;

        return (
            <aside block="CategoryPage" elem="ActionBar">
                <div>
                    <ClickOutside
                      onClick={ onOrderByOutsideClick }
                      key="minicart"
                    >
                        <div block="CategoryPage" elem="OrderByWrapper">
                            { this.renderOrderByToggle() }
                            { this.renderOrderByOverlay() }
                        </div>
                    </ClickOutside>

                </div>
                { this.renderFiltersButton() }
                <div className="layout-button-container">
                    { this.renderLayoutSwitchers() }
                </div>
            </aside>
        );
    }

    renderCategoryProductList() {
        const {
            filter,
            search,
            selectedSort,
            selectedFilters,
            isMatchingListFilter,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
            pageSize,
            category: {
                name,
            },
        } = this.props;

        const { activeLayoutType } = this.state;

        setLoadedFlag();

        if (!this.displayProducts()) {
            return null;
        }

        return (
            <div
              block="CategoryPage"
              elem="ProductListWrapper"
              className={ String(name).replaceAll(' ', '_') }
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                { this.renderItemsCount(true) }
                <CategoryProductList
                  pageSize={ pageSize }
                  filter={ filter }
                  search={ search }
                  sort={ selectedSort }
                  selectedFilters={ selectedFilters }
                  isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                  isMatchingListFilter={ isMatchingListFilter }
                  isMatchingInfoFilter={ isMatchingInfoFilter }
                  layout={ activeLayoutType }
                />
            </div>
        );
    }

    renderCategoryFilterPromoButton() {
        const filterButtons = [{
            title: __('TUTTO'),
            path: __('/offerte/promo-camere-camerette.html'),
        }, {
            title: __('CAMERE'),
            path: __('/offerte/promo-camere-camerette/camere.html'),
        }, {
            title: __('CAMERETTE'),
            path: __('/offerte/promo-camere-camerette/camerette.html'),
        }, {
            title: __('RETI E MATERASSI'),
            path: __('/offerte/promo-camere-camerette/reti-e-materassi.html'),
        }, {
            title: __('LETTI'),
            path: __('/offerte/promo-camere-camerette/letti.html'),
        }, {
            title: __('ACCESSORI ARMADI'),
            path: __('/offerte/promo-camere-camerette/accessori-armadi.html'),
        },
        {
            title: __('LIBRERIE'),
            path: __('/offerte/promo-camere-camerette/librerie.html'),
        },
        {
            title: __('SEDIE GIREVOLI'),
            path: __('/offerte/promo-camere-camerette/sedie-girevoli.html'),
        }];

        return this.isCameraCategory() && (
            <div>
                <div block="CategoryPage" elem="FilterPromo" className="desktop">
                    { filterButtons.map((button) => (
                        <button
                          disabled={ window.location.pathname === String(button.path) }
                          onClick={ () => window.location.href = button.path }
                        >
                            { button.title }
                        </button>
                    )) }
                </div>
                <div block="CategoryPage" elem="FilterPromo" className="mobile">
                    <div>
                        <button
                          id="PromoFilter"
                          onClick={ () => this.setState((prevState) => ({ hide: !prevState.hide })) }
                          className={ this.state.hide ? 'clicked' : '' }
                        >
                            { filterButtons.find((button) => window.location.pathname === String(button.path)).title }
                        </button>
                        { this.state.hide && (
                            <div className="dropDown">
                                { filterButtons.filter((button) => window.location.pathname !== String(button.path))
                                    .map((button) => (
                                        <button
                                          onClick={ () => window.location.href = button.path }
                                        >
                                            { button.title }
                                        </button>
                                    )) }
                            </div>
                        ) }
                    </div>
                </div>
            </div>
        );
    }

    isCameraCategory = () => window.location.pathname.includes('/offerte/promo-camere-camerette');

    renderFilterOverlay() {
        const {
            filters,
            selectedFilters,
            isMatchingInfoFilter,
            isSearchPage,
            mobileBackdrop,
        } = this.props;

        const { category: { is_anchor } } = this.props;

        if (!this.displayProducts()) {
            return null;
        }

        return (
            <Suspense fallback={ this.renderFilterPlaceholder() || null }>
                <CategoryFilterOverlay
                  availableFilters={ filters }
                  customFiltersValues={ selectedFilters }
                  isMatchingInfoFilter={ isMatchingInfoFilter }
                  isCategoryAnchor={ !!is_anchor }
                  isSearchPage={ isSearchPage }
                  renderPlaceholder={ this.renderPlaceholder }
                  categoryPageRef={ this.categoryPageRef }
                  mobileBackdrop={ mobileBackdrop }
                />
            </Suspense>
        );
    }

    renderContent() {
        return (
            <>
                { this.renderCategoryTop() }
                { this.renderCategoryPageFilterTag() }
                { /* { this.renderCategoryTitle() } */ }
                { this.renderCategoryFilterPromoButton() }
                { this.renderActionBar() }
                { this.renderFilterOverlay() }
                { this.renderCategoryProductList() }
                { this.renderCategoryDescription() }
            </>
        );
    }

    render() {
        const hideProducts = !this.displayProducts();

        return (
            <main block="CategoryPage">
                { window.location.pathname.indexOf('angolo-delle-occasioni') > -1 && (
                    <meta name="robots" content="noindex, nofollow" />
                ) }
                <IfStore storeCode="default">
                    { this.renderCertificationHeader() }
                </IfStore>
                <ContentWrapper
                  wrapperMix={ {
                      block: 'CategoryPage',
                      elem: 'Wrapper',
                      mods: { hideProducts },
                  } }
                  label="Category page"
                >
                    { this.renderContent() }
                </ContentWrapper>
            </main>
        );
    }
}

export default CategoryPageComponent;
