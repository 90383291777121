import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import { CategoryType } from 'Type/ProductList.type';

import CategoryNav from './CategoryNav.component';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/create-config-files
export const SCROLL_STEP = 211;

/** @namespace Pwa/Component/CategoryNav/Container */
export class CategoryNavContainer extends PureComponent {
    static propTypes = {
        category: CategoryType.isRequired,
        isCurrentCategoryLoaded: PropTypes.bool.isRequired,
    };

    containerFunctions = {
        scrollLeft: this.scrollLeft.bind(this),
        scrollRight: this.scrollRight.bind(this),
    };

    __construct() {
        this.wrapperRef = createRef();
        this.state = {
            offSet: 0,
        };
    }

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    scrollLeft() {
        if (this.wrapperRef.current.scrollLeft - SCROLL_STEP > 0) {
            this.setState(() => ({
                offSet: this.wrapperRef.current.scrollLeft - SCROLL_STEP,
            }));
        } else {
            this.setState(() => ({
                offSet: 0,
            }));
        }
    }

    scrollRight() {
        const maxScrollLeft = this.wrapperRef.current.scrollWidth - this.wrapperRef.current.clientWidth;

        if (maxScrollLeft > this.wrapperRef.current.scrollLeft + SCROLL_STEP) {
            this.setState(() => ({
                offSet: this.wrapperRef.current.scrollLeft + SCROLL_STEP,
            }));
        } else {
            this.setState(() => ({
                offSet: maxScrollLeft,
            }));
        }
    }

    render() {
        const { category, isCurrentCategoryLoaded } = this.props;
        const { offSet } = this.state;

        return (
            <CategoryNav
              { ...this.containerFunctions }
              { ...this.containerProps() }
              category={ category }
              isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
              wrapperRef={ this.wrapperRef }
              offSet={ offSet }
            />
        );
    }
}

export default CategoryNavContainer;
