import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import TextPlaceholder from 'Component/TextPlaceholder';
import { CategoryTreeType } from 'Type/Category.type';

import './CategoryDescription.style';

/** @namespace Pwa/Component/CategoryDescription/Component */
export class CategoryDescriptionComponent extends PureComponent {
    static propTypes = {
        category: CategoryTreeType.isRequired,
        isCurrentCategoryLoaded: PropTypes.bool,
    };

    static defaultProps = {
        isCurrentCategoryLoaded: true,
    };

    renderCategoryDescription() {
        const {
            category: { description, id },
            isCurrentCategoryLoaded,
        } = this.props;

        if (!id || !isCurrentCategoryLoaded) {
            return this.renderCategoryDescriptionPlaceholder();
        }

        if (!description) {
            return null;
        }

        return <Html content={ description } />;
    }

    renderCategoryDescriptionPlaceholder() {
        return (
            <p>
                <TextPlaceholder length="long" />
            </p>
        );
    }

    render() {
        return (
            <article block="CategoryDescription">
                <div block="CategoryDescription" elem="Description">
                    { this.renderCategoryDescription() }
                </div>
            </article>
        );
    }
}

export default CategoryDescriptionComponent;
