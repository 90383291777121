import { Field } from '@tilework/opus';

import {
    CategoryQuery as SourceCategoryQuery,
} from 'SourceQuery/Category.query';

/** @namespace Pwa/Query/Category/Query */
export class CategoryQuery extends SourceCategoryQuery {
    _getMcNavField() {
        return new Field('mc_nav')
            .addFieldList(this._getMcNavFields());
    }

    _getMcFiltersEnabledField() {
        return new Field('mc_filters_enabled');
    }

    _getMcAttributeSetsField() {
        return new Field('mc_attribute_sets')
            .addFieldList(this._getMcAttributeSetsFields());
    }

    _getMcNavFields() {
        return [
            'photo',
            'bigPhoto',
            'name',
            'url',
            'product_url',
        ];
    }

    _getMcAttributeSetsFields() {
        return [
            'name',
            'attributes',
        ];
    }

    _getDefaultFields() {
        const fields = super._getDefaultFields();

        fields.push(this._getMcNavField());
        fields.push(this._getMcFiltersEnabledField());
        fields.push(this._getMcAttributeSetsField());

        return fields;
    }
}

export default new CategoryQuery();
