import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import TextPlaceholder from 'Component/TextPlaceholder';
import { CategoryTreeType } from 'Type/Category.type';

import './CategoryTitle.style';

/** @namespace Pwa/Component/CategoryTitle/Component */
export class CategoryTitleComponent extends PureComponent {
    static propTypes = {
        category: CategoryTreeType.isRequired,
        isCurrentCategoryLoaded: PropTypes.bool,
    };

    static defaultProps = {
        isCurrentCategoryLoaded: true,
    };

    renderCategoryName() {
        const {
            category: { name, id, image },
            isCurrentCategoryLoaded,
        } = this.props;

        // const isOverlay = image === undefined;
        const isOverlay = image !== undefined;
        const mods = { isOverlay };
        const style = isOverlay ? {
            backgroundImage: `url(${image})`,
        } : {};

        if (id && !name) {
            return null;
        }

        if (!isCurrentCategoryLoaded) {
            return this.renderCategoryNamePlaceholder();
        }

        return (
            <div block="CategoryTitle" elem="Heading" mods={ mods } style={ style }>
                <div className="overlayText">
                    <h1 block="CategoryTitle" elem="Text">
                        <TextPlaceholder content={ name } />
                    </h1>
                </div>
            </div>
        );
    }

    renderCategoryNamePlaceholder() {
        return (
            <h1 block="CategoryDetails" elem="Heading">
                <TextPlaceholder />
            </h1>
        );
    }

    render() {
        return (
            <div block="CategoryTitle">
                { this.renderCategoryName() }
            </div>
        );
    }
}

export default CategoryTitleComponent;
