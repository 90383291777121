/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import {
    ARMADI_ANTA_BATTENTE_CATEGORY_ID,
    ARMADI_ANTA_BATTENTE_CATEGORY_NAME,
    ARMADI_ANTA_BATTENTE_REGEXP,
    ARMADI_ANTA_SCORREVOLE_CATEGORY_ID,
    ARMADI_ANTA_SCORREVOLE_CATEGORY_NAME,
    ARMADI_ANTA_SCORREVOLE_REGEXP,
    CAMERE_COMPLETE_MODERNE_CATEGORY_ID,
    CAMERE_COMPLETE_MODERNE_CATEGORY_NAME,
    CAMERE_COMPLETE_MODERNE_REGEXP,
    CAMERETTE_CATEGORY_ID,
    CAMERETTE_CATEGORY_NAME,
    CAMERETTE_FILTER_TAG_REGEXP,
    COMO_CASSETTIERE_COMODINI_CATEGORY_ID,
    COMO_CASSETTIERE_COMODINI_CATEGORY_NAME,
    COMO_CASSETTIERE_COMODINI_REGEXP,
    DIVANI_2_3_POSTI_CATEGORY_ID,
    DIVANI_2_3_POSTI_CATEGORY_NAME,
    DIVANI_2_3_POSTI_REGEXP,
    DIVANI_LETTO_CATEGORY_ID,
    DIVANI_LETTO_CATEGORY_NAME,
    DIVANI_LETTO_REGEXP,
    DIVANI_POLTRONE_RELAX_CATEGORY_ID,
    DIVANI_POLTRONE_RELAX_CATEGORY_NAME,
    DIVANI_POLTRONE_RELAX_REGEXP,
    FIXED_KITCHEN_CATEGORY_ID,
    FIXED_KITCHEN_FILTER_TAG_REGEXP,
    FIXED_KITCHEN_STRING_LENGTH,
    FORNITURE_KITCHEN_CATEGORY_NAME,
    KITCHEN_FORNITURES_CATEGORY_ID,
    KITCHEN_FORNITURES_REGEXP,
    LETTI_MATRIMONIALI_CATEGORY_ID,
    LETTI_MATRIMONIALI_CATEGORY_NAME,
    LETTI_MATRIMONIALI_REGEXP,
    LETTI_MEZZA_PIAZZA_CATEGORY_ID,
    LETTI_MEZZA_PIAZZA_CATEGORY_NAME,
    LETTI_MEZZA_PIAZZA_REGEXP,
    LETTI_SINGOLI_CATEGORY_ID,
    LETTI_SINGOLI_CATEGORY_NAME,
    LETTI_SINGOLI_REGEXP,
    MAIN_BAGNI_CATEGORY_ID,
    MAIN_BAGNI_CATEGORY_NAME,
    MAIN_BAGNI_FILTER_TAG_REGEXP,
    MAIN_CAMERE_COMPLETE_CATEGORY_ID,
    MAIN_CAMERE_COMPLETE_CATEGORY_NAME,
    MAIN_CAMERE_DA_LETTO_CATEGORY_ID,
    MAIN_CAMERE_DA_LETTO_CATEGORY_NAME,
    MAIN_CAMERE_DA_LETTO_FILTER_TAG_REGEXP,
    MAIN_CAMERETTE_CATEGORY_ID,
    MAIN_CAMERETTE_CATEGORY_NAME,
    MAIN_CAMERETTE_FILTER_TAG_REGEXP,
    MAIN_CAMERETTECOMPONIBILI_CATEGORY_ID,
    MAIN_CAMERETTECOMPONIBILI_CATEGORY_NAME,
    MAIN_CAMERETTECOMPONIBILI_FILTER_TAG_REGEXP,
    MAIN_CHILD_BAGNI_FILTER_TAG_REGEXP,
    MAIN_CHILD_CAMERE_FILTER_TAG_REGEXP,
    MAIN_CHILD_MOBILI_BAGNI_FILTER_TAG_REGEXP,
    MAIN_DIVANI_CATEGORY_ID,
    MAIN_DIVANI_CATEGORY_NAME,
    MAIN_DIVANI_FILTER_TAG_REGEXP,
    MAIN_ELETTRODOMESTICI_CATEGORY_ID,
    MAIN_ELETTRODOMESTICI_CATEGORY_NAME,
    MAIN_ELETTRODOMESTICI_FILTER_TAG_REGEXP,
    MAIN_INTERNI_ARMADIO_CATEGORY_ID,
    MAIN_INTERNI_ARMADIO_CATEGORY_NAME,
    MAIN_LETTI_CATEGORY_ID,
    MAIN_LETTI_CATEGORY_NAME,
    MAIN_LETTI_FILTER_TAG_REGEXP,
    MAIN_LETTIRETIMATERASSI_CATEGORY_FILTER_TAG_REGEXP,
    MAIN_LETTIRETIMATERASSI_CATEGORY_ID,
    MAIN_LETTIRETIMATERASSI_CATEGORY_NAME,
    MAIN_MOBILETTI_MULTIUSO_CATEGORY_ID,
    MAIN_MOBILETTI_MULTIUSO_CATEGORY_NAME,
    MAIN_MOBILI_BAGNO_A_TERRA_CATEGORY_ID,
    MAIN_MOBILI_BAGNO_A_TERRA_CATEGORY_NAME,
    MAIN_MOBILI_BAGNO_SOSPESI_CATEGORY_ID,
    MAIN_MOBILI_BAGNO_SOSPESI_CATEGORY_NAME,
    MAIN_RECLINER_ELETTRICO_CATEGORY_ID,
    MAIN_RECLINER_ELETTRICO_CATEGORY_NAME,
    MAIN_RECLINER_MANUALE_CATEGORY_ID,
    MAIN_RECLINER_MANUALE_CATEGORY_NAME,
    MAIN_RUBINETTERIA_BAGNO_CATEGORY_ID,
    MAIN_RUBINETTERIA_BAGNO_CATEGORY_NAME,
    MAIN_SCARPIERE_CATEGORY_ID,
    MAIN_SCARPIERE_CATEGORY_NAME,
    MAIN_SEDIESCRIVANIERAGAZZI_CATEGORY_ID,
    MAIN_SEDIESCRIVANIERAGAZZI_CATEGORY_NAME,
    MAIN_SEDIESCRIVANIERAGAZZI_FILTER_TAG_REGEXP,
    MAIN_SOGGIORNI_CATEGORY_FILTER_TAG_REGEXP,
    MAIN_SOGGIORNI_CATEGORY_ID,
    MAIN_SOGGIORNI_CATEGORY_NAME,
    MAIN_SPECCHI_BAGN0_CATEGORY_ID,
    MAIN_SPECCHI_BAGN0_CATEGORY_NAME,
    MIDDLE_BREAKPOINT,
    N_OF_POSITIONS_BEFORE_CM,
} from 'Component/CategoryPageFilterTag/CategoryPageFilterTag.config';
import Link from 'Component/Link/Link.component';
import { CategoryType } from 'Type/ProductList.type';

import { BACK_TO_SCHOOL, FIXED_KITCHEN_CATEGORY_NAME } from './CategoryPageFilterTag.config';

import './CategoryPageFilterTag.style';

/** @namespace Pwa/Component/CategoryPageFilterTag/Component */
export class CategoryPageFilterTagComponent extends PureComponent {
    static propTypes = {
        customRequestCategory: PropTypes.func.isRequired,
        currentCategory: CategoryType.isRequired,
        categoryRetrievedById: CategoryType.isRequired,
        isCustomCategoryRequested: PropTypes.bool.isRequired,
        clearSubCategory: PropTypes.func.isRequired,
    };

    __construct() {
        this.state = {
            isKitchen: false,
            isCameretta: false,
            isBackToSchool: false,
            isKitchenfornitures: false,
            isDivaniLetto: false,
            islettiMatrimoniali: false,
            islettiMezzaPiazza: false,
            islettiSingoli: false,
            isDivani_2_3_posti: false,
            isCamereModerne: false,
            isArmadiAntaBattente: false,
            isArmadiAntaScorrevole: false,
            isComoCassettiereComodini: false,
            isDivaniPoltroneRelax: false,
            isMainCameretteComponibili: false,
            isMainSedieScrivanieRagazzi: false,
            isMainCamereComplete: false,
            isMainBagni: false,
            isMainMobilettiMultiuso: false,
            isMainMobiliBagno: false,
            isMainRubinetteriaBagno: false,
            isMainScarpiere: false,
            isMainSpecchiBagno: false,
            isMainInterniArmadio: false,
            isMainReclinerElettrico: false,
            isMainReclinerManuale: false,
            className: '',
            parentCategory: {
                name: '',
                url_path: '',
                breadcrumbs: [{
                    name: '',
                    url_path: '',
                }],
            },
        };
    }

    async componentDidMount() {
        let mouseDown = false;
        let startX;
        let scrollLeft;
        const slider = await this.waitForElm('.swiper.swiper');

        const startDragging = (e) => {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        };

        const stopDragging = () => {
            mouseDown = false;
        };

        const move = (e) => {
            e.preventDefault();

            if (!mouseDown) {
                return;
            }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
        };

        // Add the event listeners
        slider.addEventListener('mousemove', move, false);
        slider.addEventListener('mousedown', startDragging, false);
        slider.addEventListener('mouseup', stopDragging, false);
        slider.addEventListener('mouseleave', stopDragging, false);
    }

    componentDidUpdate() {
        const { currentCategory } = this.props;

        if (currentCategory !== undefined
            && currentCategory !== null
            && Object.keys(currentCategory).length > 0
            && Object.prototype.hasOwnProperty.call(currentCategory, 'id')
            && Object.prototype.hasOwnProperty.call(currentCategory, 'name')
            && Object.prototype.hasOwnProperty.call(currentCategory, 'url_path')
        ) {
            const cameretteRegexp = new RegExp(CAMERETTE_FILTER_TAG_REGEXP);
            const backToSchool = new RegExp(BACK_TO_SCHOOL);
            const kitchenFornitures = new RegExp(KITCHEN_FORNITURES_REGEXP);
            const divaniLetto = new RegExp(DIVANI_LETTO_REGEXP);
            const lettiSingoli = new RegExp('letti/letti-singoli');
            const lettiMatrimoniali = new RegExp('letti/letti-matrimoniali');
            const lettiMezzaPiazza = new RegExp('letti/letti-una-piazza-e-mezzo');
            const divani2_3_posti = new RegExp(DIVANI_2_3_POSTI_REGEXP);
            const camereModerne = new RegExp(CAMERE_COMPLETE_MODERNE_REGEXP);
            const armadiAntaBattente = new RegExp('armadi-anta-battente');
            const armadiAntaScorrevole = new RegExp('armadi-anta-scorrevole');
            const comoCassettiereComodini = new RegExp(COMO_CASSETTIERE_COMODINI_REGEXP);
            const mainMobiliBagno = new RegExp(MAIN_CHILD_MOBILI_BAGNI_FILTER_TAG_REGEXP);
            // FATHERS CATEGORIES
            const mainCamerette = MAIN_CAMERETTE_CATEGORY_NAME;
            const mainCameretteComponibili = MAIN_CAMERETTECOMPONIBILI_CATEGORY_NAME;
            const mainSedieScrivanieRagazzi = MAIN_SEDIESCRIVANIERAGAZZI_CATEGORY_NAME;
            const mainCamere = MAIN_CAMERE_DA_LETTO_CATEGORY_NAME;
            const mainCamereComplete = MAIN_CAMERE_COMPLETE_CATEGORY_NAME;
            const mainDivani = MAIN_DIVANI_CATEGORY_NAME;
            const mainLetti = MAIN_LETTI_CATEGORY_NAME;
            const mainElettrodomestici = MAIN_ELETTRODOMESTICI_CATEGORY_NAME;
            const mainBagni = MAIN_BAGNI_CATEGORY_NAME;
            const mainLettiRetiMaterassi = new RegExp(MAIN_LETTIRETIMATERASSI_CATEGORY_FILTER_TAG_REGEXP);
            const mainSoggiorni = new RegExp(MAIN_SOGGIORNI_CATEGORY_NAME);
            const mainReclinerElettrico = MAIN_RECLINER_ELETTRICO_CATEGORY_NAME;
            const mainReclinerManuale = MAIN_RECLINER_MANUALE_CATEGORY_NAME;
            const mainDivaniPoltroneRelax = DIVANI_POLTRONE_RELAX_CATEGORY_NAME;

            /** Child of main category */
            const mainMobilettiMultiuso = MAIN_MOBILETTI_MULTIUSO_CATEGORY_NAME;
            const mainMobiliATerra = MAIN_MOBILI_BAGNO_A_TERRA_CATEGORY_NAME;
            const mainMobiliSospesi = MAIN_MOBILI_BAGNO_SOSPESI_CATEGORY_NAME;
            // const mainMobiliBagno = MAIN_MOBILI_BAGNO_CATEGORY_NAME;
            const mainRubinetteriaBagno = MAIN_RUBINETTERIA_BAGNO_CATEGORY_NAME;
            const mainScarpiere = MAIN_SCARPIERE_CATEGORY_NAME;
            const mainSpecchiBagno = MAIN_SPECCHI_BAGN0_CATEGORY_NAME;

            const mainInterniArmadio = MAIN_INTERNI_ARMADIO_CATEGORY_NAME;

            /* Here currentCategory id defined. The following conditions state in which currentCategory the filters tag have to be displayed */
            this.setState({ isKitchen: currentCategory.name.slice(0, FIXED_KITCHEN_STRING_LENGTH).toLowerCase() === FIXED_KITCHEN_CATEGORY_NAME });
            this.setState({ isCameretta: cameretteRegexp.test(currentCategory.name) });
            this.setState({ isBackToSchool: backToSchool.test(currentCategory.url_path) });
            this.setState({ isKitchenfornitures: kitchenFornitures.test(currentCategory.name) });
            this.setState({ isDivaniLetto: divaniLetto.test(currentCategory.name) });

            this.setState({ islettiSingoli: lettiSingoli.test(currentCategory.url_path) });
            this.setState({ islettiMatrimoniali: lettiMatrimoniali.test(currentCategory.url_path) });
            this.setState({ islettiMezzaPiazza: lettiMezzaPiazza.test(currentCategory.url_path) });

            this.setState({ isDivani_2_3_posti: divani2_3_posti.test(currentCategory.name) });
            this.setState({ isCamereModerne: camereModerne.test(currentCategory.name) });
            this.setState({ isArmadiAntaBattente: armadiAntaBattente.test(currentCategory.url_path) });
            this.setState({ isArmadiAntaScorrevole: armadiAntaScorrevole.test(currentCategory.url_path) });
            this.setState({ isComoCassettiereComodini: comoCassettiereComodini.test(currentCategory.name) });
            this.setState({ isMainMobiliBagno: mainMobiliBagno.test(currentCategory.name) });
            this.setState({ isMainLettiRetiMaterassi: mainLettiRetiMaterassi.test(currentCategory.name) && window.location.href.includes('offerte/letti-reti-materassi') });

            // FATHERS CATEGORIES
            this.setState({ isMainCamerette: mainCamerette === currentCategory.name.toLowerCase() });
            this.setState({ isMainCameretteComponibili: mainCameretteComponibili === currentCategory.name.toLowerCase() });
            this.setState({ isMainSedieScrivanieRagazzi: mainSedieScrivanieRagazzi === currentCategory.name.toLowerCase() });
            this.setState({ isMainCamere: mainCamere === currentCategory.name.toLowerCase() });
            this.setState({ isMainCamereComplete: mainCamereComplete === currentCategory.name.toLowerCase() });
            this.setState({ isMainDivani: mainDivani === currentCategory.name.toLowerCase() });
            this.setState({ isMainLetti: mainLetti === currentCategory.name.toLowerCase() && currentCategory.id === MAIN_LETTI_CATEGORY_ID });
            this.setState({ isMainElettrodomestici: mainElettrodomestici === currentCategory.name.toLowerCase() });
            this.setState({ isMainSoggiorni: mainSoggiorni.test(currentCategory.url_path.toLowerCase()) });
            this.setState({ isMainBagni: mainBagni === currentCategory.name.toLowerCase() });
            this.setState({ isMainReclinerElettrico: mainReclinerElettrico === currentCategory.name.toLowerCase() });
            this.setState({ isMainReclinerManuale: mainReclinerManuale === currentCategory.name.toLowerCase() });
            this.setState({ isDivaniPoltroneRelax: mainDivaniPoltroneRelax === currentCategory.name.toLowerCase() });

            /** Child of main category */
            this.setState({ isMainMobilettiMultiuso: mainMobilettiMultiuso === currentCategory.name.toLowerCase() });
            this.setState({ isMainMobiliATerra: mainMobiliATerra === currentCategory.name.toLowerCase() });
            this.setState({ isMainMobiliSospesi: mainMobiliSospesi === currentCategory.name.toLowerCase() });

            // this.setState({ isMainMobiliBagno: mainMobiliBagno === currentCategory.name.toLowerCase() });
            this.setState({ isMainRubinetteriaBagno: mainRubinetteriaBagno === currentCategory.name.toLowerCase() });
            this.setState({ isMainScarpiere: mainScarpiere === currentCategory.name.toLowerCase() });
            this.setState({ isMainSpecchiBagno: mainSpecchiBagno === currentCategory.name.toLowerCase() });
            this.setState({ isMainInterniArmadio: mainInterniArmadio === currentCategory.name.toLowerCase() });
        }
    }

    waitForElm(selector) {
        // eslint-disable-next-line consistent-return
        return new Promise((resolve) => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }
            const observer = new MutationObserver(() => {
                if (document.querySelector(selector)) {
                    resolve(document.querySelector(selector));
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true,
            });
        });
    }

    retrieveTags(item) {
        const {
            isKitchen,
            isCameretta,
            isBackToSchool,
            isKitchenfornitures,
            isDivaniLetto,
            islettiMatrimoniali,
            islettiMezzaPiazza,
            islettiSingoli,
            isDivani_2_3_posti,
            isCamereModerne,
            isArmadiAntaBattente,
            isArmadiAntaScorrevole,
            isComoCassettiereComodini,
            isMainCamerette,
            isMainCamere,
            isMainDivani,
            isMainLetti,
            isMainElettrodomestici,
            isMainSoggiorni,
            isDivaniPoltroneRelax,
            isMainCameretteComponibili,
            isMainBagni,
            isMainSedieScrivanieRagazzi,
            isMainCamereComplete,
            isMainMobilettiMultiuso,
            isMainMobiliATerra,
            isMainMobiliSospesi,
            isMainMobiliBagno,
            isMainLettiRetiMaterassi,
            isMainRubinetteriaBagno,
            isMainScarpiere,
            isMainSpecchiBagno,
            isMainInterniArmadio,
            isMainReclinerElettrico,
            isMainReclinerManuale,
        } = this.state;
        let availableTags = [];
        let regex;

        if (isBackToSchool) {
            regex = new RegExp(CAMERETTE_FILTER_TAG_REGEXP);
        } else if (isKitchen) {
            regex = new RegExp(FIXED_KITCHEN_FILTER_TAG_REGEXP);
        } else if (isCameretta) {
            regex = new RegExp(CAMERETTE_FILTER_TAG_REGEXP);
        } else if (isKitchenfornitures) {
            regex = new RegExp(KITCHEN_FORNITURES_REGEXP);
        } else if (isDivaniLetto) {
            regex = new RegExp(DIVANI_LETTO_REGEXP);
        } else if (isDivani_2_3_posti) {
            regex = new RegExp(DIVANI_2_3_POSTI_REGEXP);
        } else if (isCamereModerne) {
            regex = new RegExp(CAMERE_COMPLETE_MODERNE_REGEXP);
        } else if (isArmadiAntaBattente) {
            regex = new RegExp(ARMADI_ANTA_BATTENTE_REGEXP);
        } else if (isArmadiAntaScorrevole) {
            regex = new RegExp(ARMADI_ANTA_SCORREVOLE_REGEXP);
        } else if (isComoCassettiereComodini) {
            regex = new RegExp(COMO_CASSETTIERE_COMODINI_REGEXP);
        } else if (isMainCamerette) {
            regex = new RegExp(MAIN_CAMERETTE_FILTER_TAG_REGEXP);
        } else if (isMainCamere) {
            regex = new RegExp(MAIN_CAMERE_DA_LETTO_FILTER_TAG_REGEXP);
        } else if (isMainDivani) {
            regex = new RegExp(MAIN_DIVANI_FILTER_TAG_REGEXP);
        } else if (isMainLetti) {
            regex = new RegExp(MAIN_LETTI_FILTER_TAG_REGEXP);
        } else if (isMainElettrodomestici) {
            regex = new RegExp(MAIN_ELETTRODOMESTICI_FILTER_TAG_REGEXP);
        } else if (isMainSoggiorni) {
            regex = new RegExp(MAIN_SOGGIORNI_CATEGORY_FILTER_TAG_REGEXP);
        } else if (isDivaniPoltroneRelax) {
            regex = new RegExp(DIVANI_POLTRONE_RELAX_REGEXP);
        } else if (isMainCameretteComponibili) {
            regex = new RegExp(MAIN_CAMERETTECOMPONIBILI_FILTER_TAG_REGEXP);
        } else if (isMainBagni) {
            regex = new RegExp(MAIN_BAGNI_FILTER_TAG_REGEXP);
        } else if (isMainSedieScrivanieRagazzi) {
            regex = new RegExp(MAIN_SEDIESCRIVANIERAGAZZI_FILTER_TAG_REGEXP);
        } else if (isMainCamereComplete) {
            regex = new RegExp(MAIN_CHILD_CAMERE_FILTER_TAG_REGEXP);
        } else if (isMainMobilettiMultiuso || isMainRubinetteriaBagno || isMainScarpiere || isMainSpecchiBagno || isMainMobiliATerra || isMainMobiliSospesi) {
            regex = new RegExp(MAIN_CHILD_BAGNI_FILTER_TAG_REGEXP);
        } else if (isMainInterniArmadio) {
            regex = new RegExp(MAIN_CHILD_CAMERE_FILTER_TAG_REGEXP);
        } else if (isMainReclinerElettrico) {
            regex = new RegExp(DIVANI_POLTRONE_RELAX_REGEXP);
        } else if (isMainReclinerManuale) {
            regex = new RegExp(DIVANI_POLTRONE_RELAX_REGEXP);
        } else if (isMainMobiliBagno) {
            regex = new RegExp(MAIN_CHILD_MOBILI_BAGNI_FILTER_TAG_REGEXP);
        } else if (islettiMatrimoniali) {
            regex = new RegExp(LETTI_MATRIMONIALI_REGEXP);
        } else if (islettiSingoli) {
            regex = new RegExp(LETTI_SINGOLI_REGEXP);
        } else if (islettiMezzaPiazza) {
            regex = new RegExp(LETTI_MEZZA_PIAZZA_REGEXP);
        } else if (isMainLettiRetiMaterassi) {
            regex = new RegExp(MAIN_LETTIRETIMATERASSI_CATEGORY_FILTER_TAG_REGEXP);
        }

        if (regex !== undefined && Object.prototype.hasOwnProperty.call(item, 'children') && Array.isArray(item.children)
            && item.children.length > 0) {
            item.children.forEach((ele) => {
                if (Object.prototype.hasOwnProperty.call(ele, 'name') && (typeof ele.name === 'string')
                    && ele.name.length > 0 && regex.test(ele.name)) {
                    availableTags.push(ele);
                }
            });
        }

        if (isCameretta || isBackToSchool) {
            /** order tags for camerette * */
            availableTags.forEach((ele, idx) => {
                if (ele.name.toLowerCase().trim() === 'camerette composizione fissa') {
                    availableTags.splice(idx, 1);
                    availableTags.unshift(ele);
                }

                if (ele.name.toLowerCase().trim() === 'sedie per scrivania ragazzi') {
                    availableTags.splice(idx, 1);
                    availableTags.push(ele);
                }
            });
        }

        if (isMainInterniArmadio) {
            /** order tags for camerette * */
            availableTags.forEach((ele, idx) => {
                if (ele.name.toLowerCase().trim() === 'comò, cassettiere e comodini') {
                    availableTags.splice(idx, 1);
                    availableTags.unshift(ele);
                }

                if (ele.name.toLowerCase().trim() === 'armadi ad anta scorrevole') {
                    availableTags.splice(idx, 1);
                    availableTags.unshift(ele);
                }

                if (ele.name.toLowerCase().trim() === 'armadi ad anta battente') {
                    availableTags.splice(idx, 1);
                    availableTags.unshift(ele);
                }

                if (ele.name.toLowerCase().trim() === 'camere complete classiche') {
                    availableTags.splice(idx, 1);
                    availableTags.unshift(ele);
                }

                if (ele.name.toLowerCase().trim() === 'camere complete moderne') {
                    availableTags.splice(idx, 1);
                    availableTags.unshift(ele);
                }
            });
        }

        if (isMainCamereComplete) {
            /** order tags for camerette * */
            availableTags.forEach((ele, idx) => {
                if (ele.name.toLowerCase().trim() === 'interni armadio') {
                    availableTags.splice(idx, 1);
                    availableTags.push(ele);
                }

                if (ele.name.toLowerCase().trim() === 'comò, cassettiere e comodini') {
                    availableTags.splice(idx, 1);
                    availableTags.unshift(ele);
                }

                if (ele.name.toLowerCase().trim() === 'armadi ad anta scorrevole') {
                    availableTags.splice(idx, 1);
                    availableTags.unshift(ele);
                }

                if (ele.name.toLowerCase().trim() === 'armadi ad anta battente') {
                    availableTags.splice(idx, 1);
                    availableTags.unshift(ele);
                }

                if (ele.name.toLowerCase().trim() === 'camere complete moderne') {
                    availableTags.splice(idx, 1);
                    availableTags.unshift(ele);
                }
            });
        }

        if (isMainCameretteComponibili || isMainSedieScrivanieRagazzi) {
            /** order tags for camerette * */
            availableTags.forEach((ele, idx) => {
                if (ele.name.toLowerCase().trim() === 'camerette composizione fissa') {
                    availableTags.splice(idx, 1);
                    availableTags.unshift(ele);
                }

                if (ele.name.toLowerCase().trim() === 'sedie per scrivania ragazzi') {
                    availableTags.splice(idx, 1);
                    availableTags.push(ele);
                }

                if (ele.name.toLowerCase().trim() === 'camerette') {
                    availableTags.splice(idx, 1);
                    availableTags.push(ele);
                }
            });
        }

        if (isMainCamere) {
            /** order tags for camere * */
            availableTags = availableTags.sort(this.compare);
            availableTags.forEach((ele, idx) => {
                if (ele.name.toLowerCase().trim() === 'camere complete classiche') {
                    availableTags.splice(idx, 1);
                    availableTags.unshift(ele);
                }

                if (ele.name.toLowerCase().trim() === 'camere complete moderne') {
                    availableTags.splice(idx, 1);
                    availableTags.unshift(ele);
                }
            });
        }

        return availableTags;
    }

    compare(a, b) {
        if (a.name < b.name) {
            return -1;
        }

        if (a.name > b.name) {
            return 1;
        }

        return 0;
    }

    renderCamerettaName(string) {
        if (string.toLowerCase() === CAMERETTE_CATEGORY_NAME) {
            return 'TUTTO';
        }

        return string.trim();
    }

    renderKitchenName(string) {
        if (string.toLowerCase() === FIXED_KITCHEN_CATEGORY_NAME) {
            return 'TUTTO';
        }

        return string.slice((string.indexOf('cm') - N_OF_POSITIONS_BEFORE_CM)).trim();
    }

    renderLettiRetiMaterassiName(string, categoryName) {
        if (string.toLowerCase() === categoryName) {
            return 'TUTTO';
        }

        return string.trim();
    }

    renderGenericName(string, categoryName) {
        if (string.toLowerCase() === categoryName) {
            return 'TUTTO';
        }

        return string.trim();
    }

    renderExceptionName(string) {
        if (string.toLowerCase() === MAIN_CAMERETTECOMPONIBILI_CATEGORY_NAME) {
            return 'CAMERETTE COMPONIBILI SU MISURA';
        }

        if (string.toLowerCase() === 'camerette') {
            return 'TUTTO';
        }

        return string.trim();
    }

    removeUnaFromBeds(string = '') {
        if (string.length > 0 && string.slice(0, 3) === 'una') {
            return string.slice(4);
        }

        return string;
    }

    renderFilterTags(tags, parentCategory) {
        const {
            isKitchen,
            isCameretta,
            className,
            isBackToSchool,
            isKitchenfornitures,
            isDivaniLetto,
            islettiMatrimoniali,
            islettiMezzaPiazza,
            islettiSingoli,
            isDivani_2_3_posti,
            isCamereModerne,
            isArmadiAntaBattente,
            isArmadiAntaScorrevole,
            isComoCassettiereComodini,
            isMainCamerette,
            isMainCamere,
            isMainDivani,
            isMainLetti,
            isMainElettrodomestici,
            isMainSoggiorni,
            isDivaniPoltroneRelax,
            isMainCameretteComponibili,
            isMainBagni,
            isMainSedieScrivanieRagazzi,
            isMainCamereComplete,
            isMainMobilettiMultiuso,
            isMainMobiliATerra,
            isMainMobiliSospesi,
            isMainMobiliBagno,
            isMainRubinetteriaBagno,
            isMainScarpiere,
            isMainSpecchiBagno,
            isMainInterniArmadio,
            isMainReclinerElettrico,
            isMainReclinerManuale,
            isMainLettiRetiMaterassi,
        } = this.state;

        const {
            currentCategory, clearSubCategory, categoryRetrievedById,
        } = this.props;

        const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        // const isSmartPhone = (width <= SMARTPHONE_BREAKPOINT);
        // const isMobile = (width <= MOBILE_BREAKPOINT);
        const isMiddle = (width <= MIDDLE_BREAKPOINT);

        // const isMiddleCamerette = (width <= (MIDDLE_BREAKPOINT - 40));
        // const isDesktop = (width >= DESKTOP_BREAKPOINT);
        // const initialSlide = 0;
        if (!isMainCameretteComponibili
            && !isMainSedieScrivanieRagazzi
            && !isMainInterniArmadio
            && !isMainCamereComplete
            && !isMainMobilettiMultiuso
            && !isMainMobiliATerra
            && !isMainMobiliSospesi
            && !isMainRubinetteriaBagno
            && !isMainScarpiere
            && !isMainSpecchiBagno
            && !isMainReclinerElettrico
            && !isMainReclinerManuale) {
            tags.unshift(parentCategory);
        }

        /* ** reorder tag ** */
        if (isMiddle && parentCategory.url !== window.location.pathname) {
            tags.every((item, idx) => {
                if (item.url === window.location.pathname) {
                    tags.splice(idx, 1);
                    tags.unshift(item);

                    return false;
                }

                return true;
            });
        }

        // // eslint-disable-next-line fp/no-let
        // let slidesPerView = 'auto';
        // /* due to slides width personalization, Swiper continues scrolling even after slides end */
        // if (isBed && isSmartPhone) {
        //     slidesPerView = 3;
        // }
        // if (isCameretta && isSmartPhone) {
        //     slidesPerView = 1.8;
        // }
        // if (isCameretta && !isSmartPhone && isMobile) {
        //     slidesPerView = 1.9;
        // }
        // if (isCameretta && !isSmartPhone && !isMobile && isMiddleCamerette) {
        //     slidesPerView = 1.98;
        // }
        if (isKitchen || isKitchenfornitures || isMainElettrodomestici) {
            this.setState({ className: 'isKitchen' });
        } else if (isCameretta || isMainCamerette || isMainCameretteComponibili || isMainSedieScrivanieRagazzi) {
            this.setState({ className: 'isCameretta' });
        } else if (isDivaniLetto || isDivani_2_3_posti || isMainDivani || isDivaniPoltroneRelax || isMainReclinerElettrico || isMainReclinerManuale) {
            this.setState({ className: 'isDivano' });
        } else if (isCamereModerne || isArmadiAntaBattente || isArmadiAntaScorrevole || isComoCassettiereComodini || isMainCamere || isMainCamereComplete || isMainInterniArmadio) {
            this.setState({ className: 'isCamera' });
        } else if (isMainSoggiorni) {
            this.setState({ className: 'isSoggiorno' });
        } else if (isMainBagni || isMainMobilettiMultiuso || isMainMobiliBagno || isMainRubinetteriaBagno || isMainScarpiere || isMainSpecchiBagno || isMainMobiliATerra || isMainMobiliSospesi) {
            this.setState({ className: 'isBagno' });
        } else if (islettiMatrimoniali || islettiSingoli || islettiMezzaPiazza || isMainLetti || isMainLettiRetiMaterassi) {
            this.setState({ className: 'isBed' });
        }

        if (isBackToSchool) {
            this.setState({ className: 'isCameretta' });
        }

        clearSubCategory();

        let parentcategorySwiper = '';
        const regexParentCategorySwiper = new RegExp('\\/.*\\/.*\\/.*');

        if (parentCategory.url === window.location.pathname) {
            parentcategorySwiper = 'parent-category-swiper';
        } else if (isCameretta && !regexParentCategorySwiper.test(currentCategory.url)) {
            parentcategorySwiper = 'parent-category-swiper';
        }

        return (
            <div
              block="CategoryPageFilterTag"
              elem="Swiper"
              className={ className }
            >
                <div className={ `${parentcategorySwiper} swiper` }>
                    { this.isNotParent() && !isMainLettiRetiMaterassi && (
                        <div
                          className="swiper-slide"
                        >
                            <Link
                              to={ categoryRetrievedById.url }
                              block="CategoryPageFilterTag"
                              elem="Swiper-Link"
                              draggable="false"
                            >
                                <span
                                  block="CategoryPageFilterTag"
                                  elem="Swiper-Text"
                                >
                                    { __('All') }
                                </span>
                            </Link>
                        </div>
                    ) }
                    { tags.map((item, idx) => (
                        <div
                          key={ idx }
                          className={ item.url === window.location.pathname ? 'current-category swiper-slide' : 'swiper-slide' }
                          aria-label={ item.url }
                          id={ window.location.pathname }
                        >
                            <Link
                              to={ item.url }
                              block="CategoryPageFilterTag"
                              elem="Swiper-Link"
                              draggable="false"
                            >
                               <span
                                 block="CategoryPageFilterTag"
                                 elem="Swiper-Text"
                               >
                                   { isKitchen ? this.renderKitchenName(item.name) : null }
                                   { (isCameretta || isBackToSchool) ? this.renderCamerettaName(item.name) : null }
                                   { isKitchenfornitures ? this.renderGenericName(item.name, FORNITURE_KITCHEN_CATEGORY_NAME) : null }
                                   { isDivaniLetto ? this.renderGenericName(item.name, DIVANI_LETTO_CATEGORY_NAME) : null }
                                   { islettiMatrimoniali ? this.renderGenericName(item.name, LETTI_MATRIMONIALI_CATEGORY_NAME) : null }
                                   { islettiSingoli ? this.renderGenericName(item.name, LETTI_SINGOLI_CATEGORY_NAME) : null }
                                   { islettiMezzaPiazza ? this.renderGenericName(item.name, LETTI_MEZZA_PIAZZA_CATEGORY_NAME) : null }
                                   { isDivani_2_3_posti ? this.renderGenericName(item.name, DIVANI_2_3_POSTI_CATEGORY_NAME) : null }
                                   { isCamereModerne ? this.renderGenericName(item.name, CAMERE_COMPLETE_MODERNE_CATEGORY_NAME) : null }
                                   { isArmadiAntaBattente ? this.renderGenericName(item.name, ARMADI_ANTA_BATTENTE_CATEGORY_NAME) : null }
                                   { isArmadiAntaScorrevole ? this.renderGenericName(item.name, ARMADI_ANTA_SCORREVOLE_CATEGORY_NAME) : null }
                                   { isComoCassettiereComodini ? this.renderGenericName(item.name, COMO_CASSETTIERE_COMODINI_CATEGORY_NAME) : null }
                                   { isMainCamerette ? this.renderGenericName(item.name, MAIN_CAMERETTE_CATEGORY_NAME) : null }
                                   { isMainCameretteComponibili ? this.renderGenericName(item.name, MAIN_CAMERETTE_CATEGORY_NAME) : null }
                                   { isMainSedieScrivanieRagazzi ? this.renderGenericName(item.name, MAIN_CAMERETTE_CATEGORY_NAME) : null }
                                   { isMainCamere ? this.renderGenericName(item.name, MAIN_CAMERE_DA_LETTO_CATEGORY_NAME) : null }
                                   { isMainCamereComplete ? this.renderGenericName(item.name, MAIN_CAMERE_DA_LETTO_CATEGORY_NAME) : null }
                                   { isMainDivani ? this.renderGenericName(item.name, MAIN_DIVANI_CATEGORY_NAME) : null }
                                   { isMainLetti ? this.renderGenericName(item.name, MAIN_LETTI_CATEGORY_NAME) : null }
                                   { isMainElettrodomestici ? this.renderGenericName(item.name, MAIN_ELETTRODOMESTICI_CATEGORY_NAME) : null }
                                   { isMainSoggiorni ? this.renderGenericName(item.name, MAIN_SOGGIORNI_CATEGORY_NAME) : null }
                                   { isDivaniPoltroneRelax ? this.renderGenericName(item.name, DIVANI_POLTRONE_RELAX_CATEGORY_NAME) : null }
                                   { isMainBagni ? this.renderGenericName(item.name, MAIN_BAGNI_CATEGORY_NAME) : null }
                                   { isMainMobilettiMultiuso ? this.renderGenericName(item.name, MAIN_BAGNI_CATEGORY_NAME) : null }
                                   { isMainMobiliATerra ? this.renderGenericName(item.name, MAIN_BAGNI_CATEGORY_NAME) : null }
                                   { isMainMobiliSospesi ? this.renderGenericName(item.name, MAIN_BAGNI_CATEGORY_NAME) : null }
                                   { isMainRubinetteriaBagno ? this.renderGenericName(item.name, MAIN_BAGNI_CATEGORY_NAME) : null }
                                   { isMainScarpiere ? this.renderGenericName(item.name, MAIN_BAGNI_CATEGORY_NAME) : null }
                                   { isMainSpecchiBagno ? this.renderGenericName(item.name, MAIN_BAGNI_CATEGORY_NAME) : null }
                                   { isMainInterniArmadio ? this.renderGenericName(item.name, MAIN_INTERNI_ARMADIO_CATEGORY_NAME) : null }
                                   { isMainReclinerElettrico ? this.renderGenericName(item.name, MAIN_RECLINER_ELETTRICO_CATEGORY_NAME) : null }
                                   { isMainLettiRetiMaterassi ? this.renderLettiRetiMaterassiName(item.name, MAIN_LETTIRETIMATERASSI_CATEGORY_NAME) : null }
                                   { isMainReclinerManuale ? this.renderGenericName(item.name, MAIN_RECLINER_MANUALE_CATEGORY_NAME) : null }
                               </span>
                            </Link>
                        </div>
                    )) }
                </div>
            </div>
        );
    }

    isNotParent() {
        const {
            isMainCameretteComponibili, isMainSedieScrivanieRagazzi, isMainCamereComplete, isMainMobilettiMultiuso, isMainRubinetteriaBagno, isMainScarpiere, isMainSpecchiBagno, isMainMobiliATerra, isMainMobiliSospesi, isMainLettiRetiMaterassi,
        } = this.state;

        return (isMainCameretteComponibili || isMainSedieScrivanieRagazzi || isMainCamereComplete || isMainMobilettiMultiuso || isMainRubinetteriaBagno || isMainScarpiere || isMainSpecchiBagno || isMainMobiliATerra || isMainMobiliSospesi || isMainLettiRetiMaterassi);
    }

    retrieveParentCategory() {
        const {
            currentCategory, customRequestCategory,
            isCustomCategoryRequested,
            categoryRetrievedById,
        } = this.props;

        if (currentCategory !== undefined
            && currentCategory !== null
            && Object.keys(currentCategory).length > 0
            && Object.prototype.hasOwnProperty.call(currentCategory, 'id')
            && Object.prototype.hasOwnProperty.call(currentCategory, 'name')
            && Object.prototype.hasOwnProperty.call(currentCategory, 'url_path')
        ) {
            const {
                isKitchen,
                isCameretta,
                isBackToSchool,
                isKitchenfornitures,
                isDivaniLetto,
                islettiMatrimoniali,
                islettiSingoli,
                islettiMezzaPiazza,
                isDivani_2_3_posti,
                isCamereModerne,
                isArmadiAntaBattente,
                isArmadiAntaScorrevole,
                isComoCassettiereComodini,
                isMainCamerette,
                isMainCamere,
                isMainDivani,
                isMainLetti,
                isMainElettrodomestici,
                isMainSoggiorni,
                isDivaniPoltroneRelax,
                isMainCameretteComponibili,
                isMainBagni,
                isMainSedieScrivanieRagazzi,
                isMainCamereComplete,
                isMainMobilettiMultiuso,
                isMainMobiliATerra,
                isMainMobiliSospesi,
                isMainMobiliBagno,
                isMainRubinetteriaBagno,
                isMainScarpiere,
                isMainSpecchiBagno,
                isMainInterniArmadio,
                isMainReclinerElettrico,
                isMainReclinerManuale,
                isMainLettiRetiMaterassi,
            } = this.state;

            if (isKitchen || isCameretta || isBackToSchool || isKitchenfornitures || isDivaniLetto || isDivani_2_3_posti
                || isCamereModerne || isArmadiAntaBattente || isArmadiAntaScorrevole || isComoCassettiereComodini || isMainCamerette
                || isMainCamere || isMainInterniArmadio || isMainDivani || isMainLetti || isMainElettrodomestici || isMainSoggiorni || isDivaniPoltroneRelax || isMainCamereComplete
                || isMainSedieScrivanieRagazzi || isMainCameretteComponibili
                || isMainBagni || isMainMobilettiMultiuso || isMainLettiRetiMaterassi || isMainMobiliATerra || isMainMobiliSospesi || isMainMobiliBagno || isMainRubinetteriaBagno || isMainScarpiere || isMainSpecchiBagno || isMainReclinerElettrico || isMainReclinerManuale || islettiMatrimoniali || islettiSingoli || islettiMezzaPiazza) {
                if (!isCustomCategoryRequested) {
                    /* retrieve kitchen parent */
                    if (isKitchen) {
                        if (currentCategory.id === FIXED_KITCHEN_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(FIXED_KITCHEN_CATEGORY_ID);
                        }
                    }

                    if (isKitchenfornitures) {
                        if (currentCategory.id === KITCHEN_FORNITURES_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(KITCHEN_FORNITURES_CATEGORY_ID);
                        }
                    }

                    if (isArmadiAntaScorrevole) {
                        if (currentCategory.id === ARMADI_ANTA_SCORREVOLE_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(ARMADI_ANTA_SCORREVOLE_CATEGORY_ID);
                        }
                    }

                    if (isCamereModerne) {
                        if (currentCategory.id === CAMERE_COMPLETE_MODERNE_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(CAMERE_COMPLETE_MODERNE_CATEGORY_ID);
                        }
                    }

                    if (isArmadiAntaBattente) {
                        if (currentCategory.id === ARMADI_ANTA_BATTENTE_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(ARMADI_ANTA_BATTENTE_CATEGORY_ID);
                        }
                    }

                    if (isDivaniLetto) {
                        if (currentCategory.id === DIVANI_LETTO_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(DIVANI_LETTO_CATEGORY_ID);
                        }
                    }

                    if (islettiSingoli) {
                        if (currentCategory.id === LETTI_SINGOLI_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(LETTI_SINGOLI_CATEGORY_ID);
                        }
                    }

                    if (islettiMatrimoniali) {
                        if (currentCategory.id === LETTI_MATRIMONIALI_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(LETTI_MATRIMONIALI_CATEGORY_ID);
                        }
                    }

                    if (islettiMezzaPiazza) {
                        if (currentCategory.id === LETTI_MEZZA_PIAZZA_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(LETTI_MEZZA_PIAZZA_CATEGORY_ID);
                        }
                    }

                    if (isDivani_2_3_posti) {
                        if (currentCategory.id === DIVANI_2_3_POSTI_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(DIVANI_2_3_POSTI_CATEGORY_ID);
                        }
                    }

                    if (isComoCassettiereComodini) {
                        if (currentCategory.id === COMO_CASSETTIERE_COMODINI_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(COMO_CASSETTIERE_COMODINI_CATEGORY_ID);
                        }
                    }

                    /* retrieve camerette fisse parent */
                    if (isCameretta || isBackToSchool) {
                        if (currentCategory.id === CAMERETTE_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(CAMERETTE_CATEGORY_ID);
                        }
                    }

                    if (isMainCamerette) {
                        if (currentCategory.id === MAIN_CAMERETTE_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(MAIN_CAMERETTE_CATEGORY_ID);
                        }
                    }

                    if (isMainCamereComplete) {
                        if (currentCategory.id === MAIN_CAMERE_COMPLETE_CATEGORY_ID) {
                            customRequestCategory(MAIN_CAMERE_DA_LETTO_CATEGORY_ID);
                        }
                    }

                    if (isMainInterniArmadio) {
                        if (currentCategory.id === MAIN_INTERNI_ARMADIO_CATEGORY_ID) {
                            customRequestCategory(MAIN_CAMERE_DA_LETTO_CATEGORY_ID);
                        }
                    }

                    if (isMainCameretteComponibili) {
                        if (currentCategory.id === MAIN_CAMERETTECOMPONIBILI_CATEGORY_ID) {
                            customRequestCategory(MAIN_CAMERETTE_CATEGORY_ID);
                        }
                    }

                    if (isMainSedieScrivanieRagazzi) {
                        if (currentCategory.id === MAIN_SEDIESCRIVANIERAGAZZI_CATEGORY_ID) {
                            customRequestCategory(MAIN_CAMERETTE_CATEGORY_ID);
                        }
                    }

                    if (isMainCamere) {
                        if (currentCategory.id === MAIN_CAMERE_DA_LETTO_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(MAIN_CAMERE_DA_LETTO_CATEGORY_ID);
                        }
                    }

                    if (isMainDivani) {
                        if (currentCategory.id === MAIN_DIVANI_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(MAIN_DIVANI_CATEGORY_ID);
                        }
                    }

                    if (isMainLettiRetiMaterassi) {
                        if (currentCategory.id === MAIN_LETTIRETIMATERASSI_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(MAIN_LETTIRETIMATERASSI_CATEGORY_ID);
                        }
                    }

                    if (isMainLetti) {
                        if (currentCategory.id === MAIN_LETTI_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(MAIN_LETTI_CATEGORY_ID);
                        }
                    }

                    if (isMainElettrodomestici) {
                        if (currentCategory.id === MAIN_ELETTRODOMESTICI_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(MAIN_ELETTRODOMESTICI_CATEGORY_ID);
                        }
                    }

                    if (isMainSoggiorni) {
                        if (currentCategory.id === MAIN_SOGGIORNI_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(MAIN_SOGGIORNI_CATEGORY_ID);
                        }
                    }

                    if (isDivaniPoltroneRelax) {
                        if (currentCategory.id === DIVANI_POLTRONE_RELAX_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(DIVANI_POLTRONE_RELAX_CATEGORY_ID);
                        }
                    }

                    if (isMainBagni) {
                        if (currentCategory.id === MAIN_BAGNI_CATEGORY_ID) {
                            this.setState({ parentCategory: currentCategory });
                        } else {
                            /* additional graphql call */
                            customRequestCategory(MAIN_BAGNI_CATEGORY_ID);
                        }
                    }

                    if (isMainMobilettiMultiuso) {
                        if (currentCategory.id === MAIN_MOBILETTI_MULTIUSO_CATEGORY_ID) {
                            customRequestCategory(MAIN_BAGNI_CATEGORY_ID);
                        }
                    }

                    if (isMainMobiliATerra) {
                        if (currentCategory.id === MAIN_MOBILI_BAGNO_A_TERRA_CATEGORY_ID) {
                            customRequestCategory(MAIN_BAGNI_CATEGORY_ID);
                        }
                    }

                    if (isMainMobiliSospesi) {
                        if (currentCategory.id === MAIN_MOBILI_BAGNO_SOSPESI_CATEGORY_ID) {
                            customRequestCategory(MAIN_BAGNI_CATEGORY_ID);
                        }
                    }

                    if (isMainRubinetteriaBagno) {
                        if (currentCategory.id === MAIN_RUBINETTERIA_BAGNO_CATEGORY_ID) {
                            customRequestCategory(MAIN_BAGNI_CATEGORY_ID);
                        }
                    }

                    if (isMainScarpiere) {
                        if (currentCategory.id === MAIN_SCARPIERE_CATEGORY_ID) {
                            customRequestCategory(MAIN_BAGNI_CATEGORY_ID);
                        }
                    }

                    if (isMainReclinerElettrico) {
                        if (currentCategory.id === MAIN_RECLINER_ELETTRICO_CATEGORY_ID) {
                            /* additional graphql call */
                            customRequestCategory(DIVANI_POLTRONE_RELAX_CATEGORY_ID);
                        }
                    }

                    if (isMainReclinerManuale) {
                        if (currentCategory.id === MAIN_RECLINER_MANUALE_CATEGORY_ID) {
                            customRequestCategory(DIVANI_POLTRONE_RELAX_CATEGORY_ID);
                        }
                    }

                    if (isMainSpecchiBagno) {
                        if (currentCategory.id === MAIN_SPECCHI_BAGN0_CATEGORY_ID) {
                            customRequestCategory(MAIN_BAGNI_CATEGORY_ID);
                        }
                    }
                } else if (isCustomCategoryRequested) {
                    if (categoryRetrievedById !== undefined
                        && categoryRetrievedById !== null
                        && Object.keys(categoryRetrievedById).length > 0) {
                        this.setState({ parentCategory: categoryRetrievedById });
                    }
                }
            }
        }
    }

    render() {
        this.retrieveParentCategory();
        const { parentCategory } = this.state;

        if (parentCategory.name !== '') {
            const tags = this.retrieveTags(parentCategory);

            /* if categories related to tags are off, nothing is displayed */
            if (tags.length > 0) {
                return (
                    <div block="CategoryPageFilterTag">
                        <div block="CategoryPageFilterTag" elem="Container">
                            { this.renderFilterTags(tags, parentCategory) }
                        </div>
                    </div>
                );
            }
        }

        return null;
    }
}

export default CategoryPageFilterTagComponent;
