import ProductListQuery from 'Query/ProductList.query';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import { showNotification } from 'Store/Notification/Notification.action';
import {
    updateInfoLoadStatus,
    updateProductListInfo,
} from 'Store/ProductListInfo/ProductListInfo.action';
import { QueryDispatcher } from 'Util/Request/QueryDispatcher';

/**
 * Product List Info Dispatcher
 * @class ProductListInfoDispatcher
 * @extends QueryDispatcher
 * @namespace Pwa/Store/ProductListInfo/Dispatcher */
export class ProductListInfoDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct('ProductListInfo');
    }

    onSuccess({ products }, dispatch, options) {
        const {
            args: {
                filter,
            },
        } = options;

        dispatch(updateProductListInfo(products, filter));
    }

    onError(error, dispatch) {
        dispatch(showNotification('error', __('Error fetching Product List Information!'), error));
        dispatch(updateNoMatch(true));
        dispatch(updateInfoLoadStatus(false));
    }

    prepareRequest(options, dispatch) {
        dispatch(updateInfoLoadStatus(true));

        return ProductListQuery.getQuery({
            ...options,
            requireInfo: true,
        });
    }
}

export default new ProductListInfoDispatcher();
