import ProductCard from 'Component/ProductCard';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import {
    ProductListPageComponent as SourceProductListPage,
} from 'SourceComponent/ProductListPage/ProductListPage.component';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

import './ProductListPage.style';

/** @namespace Pwa/Component/ProductListPage/Component */
export class ProductListPageComponent extends SourceProductListPage {
    renderPageItems() {
        const {
            items,
            selectedFilters,
            mix: {
                mods: {
                    layout = GRID_LAYOUT,
                    promo,
                } = {},
            },
        } = this.props;

        return items.map((product, i) => (
            <ProductCard
              product={ product }
              // eslint-disable-next-line react/no-array-index-key
              key={ i }
              position={ i + 1 }
              selectedFilters={ selectedFilters }
              layout={ layout }
              promo={ promo }
              { ...this.containerProps() }
              onLoad={ setLoadedFlag }
            />
        ));
    }
}

export default ProductListPageComponent;
