/* eslint-disable react/prop-types */
import { PureComponent } from 'react';

import { scrollToTop } from 'Util/Browser';

import './ResetButton.style';

/** @namespace Pwa/Component/ResetButton/Component */
export class ResetButtonComponent extends PureComponent {
    static defaultProps = {
        mix: {},
    };

    __construct(props) {
        super.__construct?.(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const { onClick, resetFilters } = this.props;

        onClick();
        resetFilters();
        scrollToTop();
    }

    render() {
        const { mix } = this.props;

        return (
            <div
              block="ResetButton"
              mix={ mix }
            >
                <button
                  onClick={ this.onClick }
                  block="ResetButton"
                  elem="Button"
                  mix={ {
                      block: 'Button',
                      mods: { isHollow: true },
                  } }
                >
                    { __('Reset all') }
                </button>
            </div>
        );
    }
}

export default ResetButtonComponent;
