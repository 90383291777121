export const SMARTPHONE_BREAKPOINT = 512;
export const MOBILE_BREAKPOINT = 768;
export const MIDDLE_BREAKPOINT = 1440;
export const DESKTOP_BREAKPOINT = 1510;

export const N_OF_POSITIONS_BEFORE_CM = 4;
export const FIXED_KITCHEN_CATEGORY_ID = 549;
export const KITCHEN_FORNITURES_CATEGORY_ID = 560;
export const DIVANI_LETTO_CATEGORY_ID = 701;
export const LETTI_SINGOLI_CATEGORY_ID = 3704;
export const LETTI_MATRIMONIALI_CATEGORY_ID = 3692;
export const LETTI_MEZZA_PIAZZA_CATEGORY_ID = 3698;
export const DIVANI_2_3_POSTI_CATEGORY_ID = 741;
export const CAMERE_COMPLETE_MODERNE_CATEGORY_ID = 3067;
export const ARMADI_ANTA_BATTENTE_CATEGORY_ID = 3097;
export const ARMADI_ANTA_SCORREVOLE_CATEGORY_ID = 3133;
export const COMO_CASSETTIERE_COMODINI_CATEGORY_ID = 615;
export const DIVANI_POLTRONE_RELAX_CATEGORY_ID = 3334;
export const FIXED_KITCHEN_STRING_LENGTH = 12;
export const FIXED_KITCHEN_CATEGORY_NAME = 'cucine fisse';

export const FORNITURE_KITCHEN_CATEGORY_NAME = 'elettrodomestici cucina';
export const DIVANI_LETTO_CATEGORY_NAME = 'divani letto';
export const LETTI_SINGOLI_CATEGORY_NAME = 'letti singoli';
export const LETTI_MATRIMONIALI_CATEGORY_NAME = 'letti matrimoniali';
export const LETTI_MEZZA_PIAZZA_CATEGORY_NAME = 'letti una piazza e mezzo';
export const DIVANI_2_3_POSTI_CATEGORY_NAME = 'divani due e tre posti';
export const CAMERE_COMPLETE_MODERNE_CATEGORY_NAME = 'camere complete moderne';
export const ARMADI_ANTA_BATTENTE_CATEGORY_NAME = 'armadi ad anta battente';
export const ARMADI_ANTA_SCORREVOLE_CATEGORY_NAME = 'armadi ad anta scorrevole';
export const COMO_CASSETTIERE_COMODINI_CATEGORY_NAME = 'comò, cassettiere, comodini';
export const DIVANI_POLTRONE_RELAX_CATEGORY_NAME = 'divani e poltrone recliner';

export const BEDS_STRING_LENGTH = 5;
export const BEDS_MACROCATEGORY_NAME = 'letti';
export const BEDS_PARENT_CATEGORY_ARRAY = [
    'letti',
    'imbottiti similpelle tessuto',
    'essenza legno',
    'ferro battuto',
];

// 'imbottiti similpelle tessuto - letto contenitore',
//     'imbottiti similpelle tessuto - letto giroletto',
//     'essenza legno - letto contenitore',
//     'essenza legno - letto giroletto'

export const BEDS_MACROCATEGORY_ID = 636;
export const FIXED_KITCHEN_FILTER_TAG_REGEXP = 'Cucine Fisse|Cucine Fisse.{5}cm';
export const BEDS_FILTER_TAG_REGEXP = 'Letti|Letti matrimoniali|Letti una piazza e mezza|Letti singoli';
export const BEDS_PANCHE_ID = 3605;
export const CAMERETTE_CATEGORY_ID = 814;
export const CAMERETTE_CATEGORY_NAME = 'camerette composizione fissa';
export const CAMERETTE_FILTER_TAG_REGEXP = '^[Cc]amerette [Cc]omposizione [Ff]issa$|^[Cc]amerette a [Pp]onte$|^[Cc]amerette 2 [Ll]etti$|^[Cc]amerette 3 [Ll]etti|^[Cc]amerette a [Ss]oppalco - [Ss]alvaspazio$';
/* sotto categorie camerette non incluse |[Cc]amerette\\s.{1}\\s.{5}|[Cc]amerette\\s.{1}\\s[Ss]oppalco\\s.{1,14}'; */
export const BACK_TO_SCHOOL = 'back-to-school';
export const KITCHEN_FORNITURES_REGEXP = '[Ee]lettrodomestici [Cc]ucina|Pp]romozioni|[Tt]ris e [Dd]uetto|[Pp]iani [Cc]ottura|[Pp]iani [Cc]ottura a [Ii]nduzione|^[Ff]orni$|^[Ff]orni a [Mm]icroonde$|^[Ll]avastoviglie da [Ii]ncasso$|^[Ff]rigoriferi [Ll]ibera [Ii]nstallazione$|^[Ff]rigoriferi da [Ii]ncasso$|^[Ll]avatrici da [Ii]ncasso$|[Cc]appe';
export const DIVANI_LETTO_REGEXP = '[Dd]ivani [Ll]etto|[Cc]lic [Cc]lac|[Ee]straibili|[Aa] [Rr]ibalta';
export const LETTI_SINGOLI_REGEXP = '[Ll]etti [Ss]ingoli|[Ll]etti [Cc]ontenitore|[Ll]etti [Gg]iroletto|[Ll]etti [Ii]mbottiti|[Ll]etti [Ii]mbottiti|[Ll]etti [Ee]ffetto [Ll]egno|[Ll]etti [Ii]n [Ff]erro [Bb]attuto';
export const LETTI_MATRIMONIALI_REGEXP = '[Ll]etti [Cc]ontenitore|[Ll]etti [Gg]iroletto|[Ll]etti [Ii]mbottiti|[Ll]etti [Ii]mbottiti|[Ll]etti [Ee]ffetto [Ll]egno|[Ll]etti [Ii]n [Ff]erro [Bb]attuto';
export const LETTI_MEZZA_PIAZZA_REGEXP = '[Ll]etti [Cc]ontenitore|[Ll]etti [Gg]iroletto|[Ll]etti [Ii]mbottiti|[Ll]etti [Ii]mbottiti|[Ll]etti [Ee]ffetto [Ll]egno|[Ll]etti [Ii]n [Ff]erro [Bb]attuto';
export const DIVANI_2_3_POSTI_REGEXP = '[Dd]ivani [Dd]ue e [Tt]re [Pp]osti|^[Dd]ivani [Dd]ue [Pp]osti$|^[Dd]ivani [Tt]re [Pp]osti$';
export const CAMERE_COMPLETE_MODERNE_REGEXP = '^[Cc]amere [Cc]omplete [Mm]oderne|^[Aa]nta [Bb]attente$|^[Aa]nta [Ss]correvole$|^[Aa]nta [Ss]correvole [Mm]axi$';
export const ARMADI_ANTA_BATTENTE_REGEXP = '^[Aa]rmadi [Aa]d [Aa]nta [Bb]attente|^[Aa]rmadio [Aa] [Pp]onte$|^[Aa]rmadio [Aa]ngolare$|^[Aa]rmadio 6 [Aa]nte$|^[Aa]rmadio 4 [Aa]nte$|^[Aa]rmadio 3 [Aa]nte$|^[Aa]rmadio 2 [Aa]nte$|^[Aa]rmadi [Ss]tile [Cc]lassico$';
export const ARMADI_ANTA_SCORREVOLE_REGEXP = '^[Aa]rmadi [Aa]d [Aa]nta [Ss]correvole|^[Aa]rmadi 2 [Aa]nte [Mm]axi|^[Aa]rmadio 3 [Aa]nte$|^[Aa]rmadio 2 [Aa]nte$';
export const COMO_CASSETTIERE_COMODINI_REGEXP = '[Cc]om[oò], [Cc]assettiere, [Cc]omodini|^[Cc]omodini|^[Cc]assettiere [Ee] [Cc]om[òo]$|^[Ss]ettimini$';
export const DIVANI_POLTRONE_RELAX_REGEXP = '^[Dd]ivani [Ee] [Pp]oltrone [Rr]ecliner|^[Rr]ecliner [Mm]anuale|^[Rr]ecliner [Ee]lettrico$';

// FATHERS CATEGORIES
export const MAIN_CAMERETTE_CATEGORY_ID = 807;
export const MAIN_CAMERETTE_CATEGORY_NAME = 'camerette';
export const MAIN_CAMERETTE_FILTER_TAG_REGEXP = '^[Cc]amerette$|^[Cc]amerette\\s[Cc]omposizione\\s[Ff]issa$|^[Cc]amerette\\s[Cc]omponibili\\s-\\s[Ss]u\\s[Mm]isura$|^[Ss]edie\\s[Pp]er\\s[Ss]crivania\\s[Rr]agazzi$';

export const MAIN_CAMERE_DA_LETTO_CATEGORY_ID = 571;
export const MAIN_CAMERE_DA_LETTO_CATEGORY_NAME = 'camere da letto';
export const MAIN_CAMERE_DA_LETTO_FILTER_TAG_REGEXP = '^[Cc]amere da letto$|^[Cc]amere\\s[Cc]omplete\\s[Mm]oderne$|^[Cc]amere\\s[Cc]omplete\\s[Cc]lassiche$|^[Aa]rmadi\\s[Aa]d\\s[Aa]nta\\s[Bb]attente$|^[Aa]rmadi\\s[Aa]d\\s[Aa]nta\\s[Ss]correvole$|^[Cc]om[òo],\\s[Cc]assettiere,\\s[Cc]omodini|^[Ii]nterni\\s[Aa]rmadio';

export const MAIN_DIVANI_CATEGORY_ID = 699;
export const MAIN_DIVANI_CATEGORY_NAME = 'divani';
export const MAIN_DIVANI_FILTER_TAG_REGEXP = '^[Dd]ivani|^[Dd]ivani\\s[Ll]etto|^[Dd]ivani\\s[Aa]ngolari|^[Dd]ivani\\s[Dd]ue\\s[Ee]\\s[Tt]re\\s[Pp]osti|^[Dd]ivani\\s[Ee]\\s[Pp]oltrone\\s[Rr]elax';

export const MAIN_LETTI_CATEGORY_ID = 636;
export const MAIN_LETTI_CATEGORY_NAME = 'letti';
export const MAIN_LETTI_FILTER_TAG_REGEXP = '^[Ll]etti|^[Ll]etti\\s[Mm]atrimoniali|^[Ll]etti\\s[Uu]na\\s[Pp]iazza\\s[Ee]\\s[Mm]ezza|^[Ll]etti\\s[Ss]ingoli|^[Pp]oltroncine\\s[Ee]\\s[Pp]anche';

export const MAIN_ELETTRODOMESTICI_CATEGORY_ID = 699;
export const MAIN_ELETTRODOMESTICI_CATEGORY_NAME = 'elettrodomestici';
export const MAIN_ELETTRODOMESTICI_FILTER_TAG_REGEXP = '^[Pp]romozioni|^[Tt]ris\\s[Ee]\\s[Dd]uetto|^[Pp]iani\\s[Cc]ottura|^[Pp]iani\\s[Cc]ottura\\s[Aa]\\s[Ii]nduzione|^[Ff]orni|^[Ff]orni|\\s[Aa]\\s[Mm]icroonde|^[Ll]avastoviglie\\s[Dd]a\\s[Ii]ncasso|^[Ff]rigoriferi\\s[Dd]a\\s[Ii]ncasso|^[Ll]avatrici\\s[Dd]a\\s[Ii]ncasso|^[Cc]appe';

export const MAIN_SOGGIORNI_CATEGORY_ID = 667;
export const MAIN_SOGGIORNI_CATEGORY_NAME = 'soggiorni';
export const MAIN_SOGGIORNI_CATEGORY_FILTER_TAG_REGEXP = '^[Ss]oggiorni\\s[Mm]oderni|^[Mm]adie\\s[Mm]oderne|^[Cc]redenze\\s[Ee]\\s[Vv]etrine|^[Mm]obili\\s[Tt][Vv]|^[Mm]obili\\s[Ii]ngresso';

export const MAIN_CAMERETTECOMPONIBILI_CATEGORY_ID = 809;
export const MAIN_CAMERETTECOMPONIBILI_CATEGORY_NAME = 'camerette componibili - su misura';
export const MAIN_CAMERETTECOMPONIBILI_FILTER_TAG_REGEXP = '^[Cc]amerette\\s[Cc]omposizione\\s[Ff]issa$|^[Cc]amerette\\s[Cc]omponibili\\s-\\s[Ss]u\\s[Mm]isura$|^[Ss]edie\\s[Pp]er\\s[Ss]crivania\\s[Rr]agazzi$';

export const MAIN_SEDIESCRIVANIERAGAZZI_CATEGORY_ID = 822;
export const MAIN_SEDIESCRIVANIERAGAZZI_CATEGORY_NAME = 'sedie per scrivania ragazzi';
export const MAIN_SEDIESCRIVANIERAGAZZI_FILTER_TAG_REGEXP = '^[Cc]amerette\\s[Cc]omposizione\\s[Ff]issa$|^[Cc]amerette\\s[Cc]omponibili\\s-\\s[Ss]u\\s[Mm]isura$|^[Ss]edie\\s[Pp]er\\s[Ss]crivania\\s[Rr]agazzi$';

export const MAIN_BAGNI_CATEGORY_ID = 856;
export const MAIN_BAGNI_CATEGORY_NAME = 'bagni';
export const MAIN_BAGNI_FILTER_TAG_REGEXP = '^[Bb]agni$|^[Mm]obiletti\\s[Mm]ultiuso$|^[Ss]carpiere$|^[Ss]pecchi\\s[Bb]agno$|^[Rr]ubinetteria\\s[Bb]agno$|^[Mm]obili\\s[Bb]agno\\s[Ss]ospesi$|^[Mm]obili\\s[Bb]agno\\s[Aa]\\s[Tt]erra$';

/** Barbatrucco Tag filtri per figli di bagni  */
// export const MAIN_MOBILI_BAGNO_CATEGORY_ID = 3198;
// export const MAIN_MOBILI_BAGNO_CATEGORY_NAME = 'mobili bagno';

export const MAIN_MOBILETTI_MULTIUSO_CATEGORY_ID = 3527;
export const MAIN_MOBILETTI_MULTIUSO_CATEGORY_NAME = 'mobiletti multiuso';

export const MAIN_SCARPIERE_CATEGORY_ID = 3200;
export const MAIN_SCARPIERE_CATEGORY_NAME = 'scarpiere';

export const MAIN_SPECCHI_BAGN0_CATEGORY_ID = 3201;
export const MAIN_SPECCHI_BAGN0_CATEGORY_NAME = 'specchi bagno';

export const MAIN_RUBINETTERIA_BAGNO_CATEGORY_ID = 3202;
export const MAIN_RUBINETTERIA_BAGNO_CATEGORY_NAME = 'rubinetteria bagno';

export const MAIN_CHILD_BAGNI_FILTER_TAG_REGEXP = '^[Mm]obiletti\\s[Mm]ultiuso$|^[Ss]carpiere$|^[Ss]pecchi\\s[Bb]agno$|^[Rr]ubinetteria\\s[Bb]agno$|^[Mm]obili\\s[Bb]agno\\s[Ss]ospesi$|^[Mm]obili\\s[Bb]agno\\s[Aa]\\s[Tt]erra$';
/** end Tag filtri per figli di bagni  */

/** Barbatrucco Tag filtri per figli di camere  */
export const MAIN_CAMERE_COMPLETE_CATEGORY_ID = 3096;
export const MAIN_CAMERE_COMPLETE_CATEGORY_NAME = 'camere complete classiche';

export const MAIN_INTERNI_ARMADIO_CATEGORY_ID = 2519;
export const MAIN_INTERNI_ARMADIO_CATEGORY_NAME = 'interni armadio';

export const MAIN_CHILD_CAMERE_FILTER_TAG_REGEXP = '^[Cc]amere\\s[Cc]omplete\\s[Mm]oderne$|^[Cc]amere\\s[Cc]omplete\\s[Cc]lassiche$|^[Aa]rmadi\\s[Aa]d\\s[Aa]nta\\s[Bb]attente$|^[Aa]rmadi\\s[Aa]d\\s[Aa]nta\\s[Ss]correvole$|^[Cc]om[òo],\\s[Cc]assettiere,\\s[Cc]omodini|^[Ii]nterni\\s[Aa]rmadio';
/** end Tag filtri per figli di camere  */

/** tag divani recliner  */
export const MAIN_RECLINER_ELETTRICO_CATEGORY_ID = 3336;
export const MAIN_RECLINER_ELETTRICO_CATEGORY_NAME = 'recliner elettrico';

export const MAIN_RECLINER_MANUALE_CATEGORY_ID = 3335;
export const MAIN_RECLINER_MANUALE_CATEGORY_NAME = 'recliner manuale';

/** tag mobili bagno */
export const MAIN_MOBILI_BAGNO_SOSPESI_CATEGORY_ID = 3526;
export const MAIN_MOBILI_BAGNO_SOSPESI_CATEGORY_NAME = 'mobili bagno sospesi';

export const MAIN_MOBILI_BAGNO_A_TERRA_CATEGORY_ID = 3525;
export const MAIN_MOBILI_BAGNO_A_TERRA_CATEGORY_NAME = 'mobili bagno a terra';

/** tag mobili bagno */
export const MAIN_COLONNE_E_PENSILI_CATEGORY_ID = 3409;
export const MAIN_COLONNE_E_PENSILI_CATEGORY_NAME = 'colonne e pensili';

export const MAIN_BASI_E_CASSETTIERE_CATEGORY_ID = 3410;
export const MAIN_BASI_E_CASSETTIERE_CATEGORY_NAME = 'basi e cassettiere';

export const MAIN_CHILD_MOBILI_BAGNI_FILTER_TAG_REGEXP = '^[Mm]obili\\s[Bb]agno\\s[Ss]ospesi$|^[Mm]obili\\s[Bb]agno\\s[Aa]\\s[Tt]erra$|^[Bb]asi\\s[Ee]\\s[Cc]assettiere$';
// FATHERS CATEGORIES
export const MAIN_LETTIRETIMATERASSI_CATEGORY_ID = 3712;
export const MAIN_LETTIRETIMATERASSI_CATEGORY_NAME = 'letti reti materassi';
export const MAIN_LETTIRETIMATERASSI_CATEGORY_FILTER_TAG_REGEXP = '^[Ll]etti\\s[Rr]eti\\s[Mm]aterassi$|^[Rr]eti$|^[Mm]aterassi|^[Ll]etti$';
